<template>
  <div class="nv-widget relative h-full">
    <div class="nv-widget__container flex px-4 items-center justify-center h-full">
      <!-- <div
        v-if="isShowOxygenToggleBtn"
        class="flex"
      >
        <div class="px-4 py-2 nv-toggle-oxygen rounded font-medium text-xs">
          {{ $t('user.try_our_new') }} &nbsp;
          <el-tooltip
            placement="bottom"
            :effect="darkMode ? 'dark' : 'light'"
          >
            <div
              slot="content"
              :style="{width: '100px'}"
              class="nv-toggle-oxygen__label"
            >
              <p class="text-xs">
                {{ $t('user.try_out_the_new') }}
              </p>
              <p class="text-xs">
                {{ $t('user.you_can_switch') }}
              </p>
            </div>
            <el-switch
              size="small"
              :value="oxygenMode"
              @change="() => { $emit('toggle-oxygen-theme') }"
            />
          </el-tooltip>
        </div>
      </div> -->
      <div
        v-if="hasSubscription"
        ref="widget-subscription"
        class="px-4 py-2 mx-4 flex items-center justify-between"
        :class="selectedDropdown === 'subscription' && isOpen && 'subscription-opened rounded'"
        @mouseenter="openWidgetDropdown('subscription')"
      >
        <div class="subscription-header flex items-center">
          <span class="subscription-header__label text-xs">{{ $t('topbar.subscription.your_plan') }}:</span>
          <div
            v-if="isSubscriptionActive()"
            class="subscription-status active flex items-center rounded-lg px-2 py-1 text-xs"
          >
            <span class="subscription-status__label">
              {{ subscriptionPackage.plan.metadata.displayName || subscriptionPackage.plan.description }}
            </span>
          </div>
          <div
            v-else
            class="subscription-status inactive flex items-center rounded-lg px-2 py-1 text-xs text-black"
          >
            <i class="icon-novo-error mr-1" />
            {{ $t("statuses.inactive") }}
          </div>
        </div>
        <i
          :class="selectedDropdown !== 'subscription'? 'icon-novo-arrow-down arrow-label': 'icon-novo-arrow-up arrow-label'"
        />
      </div>
      <div
        v-if="hasSubscription && isSmsOnboardingStatusTrial"
        ref="widget-subscription-trial"
        class="nv-widget__subscription-trial px-4 py-2 mr-4 flex items-center justify-between cursor-pointer"
        :class="selectedDropdown === 'subscription-trial' && isOpen && 'subscription-trial-opened rounded'"
        @click="openWidgetDropdown('subscription-trial')"
      >
        <div class="subscription-header flex">
          <div class="subscription-status trial flex items-center rounded-lg px-2 py-1 text-xs">
            <span class="subscription-status__label">{{ $t('topbar.subscription.trial') }}</span>
          </div>
        </div>
        <i
          :class="selectedDropdown !== 'subscription-trial'? 'icon-novo-arrow-down arrow-label': 'icon-novo-arrow-up arrow-label'"
        />
      </div>
      <div
        v-if="!hasSubscription && Object.keys(balance).length && isShowBalance"
        class="nv-widget__balance flex justify-content items-center px-4 py-2"
      >
        <span class="text-balance-label text-grey-dark text-xs">
          {{
            Boolean(userData.IsTrialAccount)
              ? $t('column_labels.trial_credits')
              : $t('column_labels.balance')
          }}
        </span>
        <span class="text-balance font-bold mx-2 block text-sm">
          {{ `${balance.Currency || ''} ${formatBalance(balance.CreditsAmount)}` }}
        </span>
        <router-link
          v-if="!Boolean(userData.IsPostPaid)"
          to="/messaging/payment"
          class="top-up-btn text-xs rounded font-semibold px-2 py-1 no-underline hover:no-underline"
        >
          <span v-if="!Boolean(userData.IsTrialAccount)">
            {{ $t('user.topup[0]').toUpperCase() }}
          </span>
          <span v-else>
            {{ $t('user.topup[1]').toUpperCase() }}
          </span>
        </router-link>
      </div>
      <div
        v-if="!hasSubscription && !Object.keys(balance).length && isShowBalance"
        class="nv-widget__balance"
      >
        <span class="loading-anim font-bold mx-2 capitalize text-xs block opacity-50">
          {{ $t('wait.loading_balance') }}
        </span>
      </div>
      <div class="nv-widget__user py-2 flex items-center justify-between">
        <div
          v-if="hasSupportEntitlement()"
          class="px-4 border-l flex items-center justify-center nv-widget__user-box"
        >
          <i
            id="widget-icon"
            ref="widget-help"
            role="button"
            class="icon-novo-support nv-widget__user-icons cursor-pointer"
            @click="openWidgetDropdown('help')"
          />
        </div>
        <div class="px-4 border-l nv-widget__user-box">
          <span
            id="widget-setting-btn"
            ref="widget-setting"
            role="button"
            :class="selectedDropdown === 'setting' ? 'active' : ''"
            class="nv-widget__user-profile rounded-full flex items-center justify-center text-xs cursor-pointer"
            @click="openWidgetDropdown('setting')"
          >{{ getUserAlias(userData) }}</span>
        </div>
      </div>
      <transition name="nv-widget-fade">
        <nav
          v-if="isOpen"
          ref="nv-widget-dropdown"
          v-click-outside="{
            exclude: ['widget-setting', 'widget-help', 'widget-subscription', 'widget-subscription-trial'],
            handler: 'closeWidgetDropdown',
          }"
          :class="getNavClassBySelectedWidget(selectedDropdown)"
          tabindex="0"
        >
          <div
            v-if="selectedDropdown === 'subscription' && Object.keys(messageBalance).length"
            class="py-1 text-xs"
          >
            <div v-if="isSubscriptionActive()">
              <div class="px-4 pt-3 pb-2">
                <div class="flex justify-between">
                  <h4>{{ $t('topbar.subscription.billing_frequency') }}</h4>
                  <div class="text-grey-darker">
                    {{ subscriptionPackage.plan.pricePoints[0].billingPeriod }}
                  </div>
                </div>
                <div class="flex flex-col mt-2">
                  <div class="flex justify-between">
                    <span>{{ $t('topbar.subscription.platform_fee') }}:</span>
                    <span class="font-medium">
                      {{ subscriptionPackage.plan.metadata.currency }}
                      {{ subscriptionPackage.plan.metadata.platformFee }}
                    </span>
                  </div>
                  <div
                    v-if="subscriptionPackage.plan.metadata.commitAmount && subscriptionPackage.plan.metadata.commitAmount !== '0'"
                    class="flex justify-between mt-1"
                  >
                    <span>{{ $t('topbar.subscription.committed_spend') }}:</span>
                    <span class="font-medium">
                      {{ subscriptionPackage.plan.metadata.currency }}
                      {{ subscriptionPackage.plan.metadata.commitAmount }}
                    </span>
                  </div>
                  <div
                    v-if="getEntitlementValue(subscriptionPackage.plan.entitlements, 'feature-free-sms')"
                    class="flex justify-between mt-1"
                  >
                    <span>{{ $t('topbar.subscription.free_sms') }}:</span>
                    <span class="font-medium">
                      {{ getEntitlementValue(subscriptionPackage.plan.entitlements, 'feature-free-sms') }}
                    </span>
                  </div>
                  <span
                    v-if="subscriptionPackage.plan.metadata.discount && subscriptionPackage.plan.metadata.discount !== '0'"
                    class="mt-1 mb-2 text-grey-darker"
                  >
                    {{ $t('topbar.subscription.discount_offer', {
                      discount: subscriptionPackage.plan.metadata.discount
                    }) }}
                  </span>
                </div>
              </div>
              <hr class="nv-widget__divider-full">
              <div class="px-4 pt-3 pb-2">
                <div class="flex justify-between">
                  <h4>{{ $t('topbar.subscription.messages_sent') }}</h4>
                  <div class="text-grey-darker">
                    {{ formatDate(getCurrentTimeZoneDate(), true) }}
                  </div>
                </div>
                <div class="flex flex-col mt-2">
                  <div class="flex justify-between">
                    <span>{{ $t('topbar.subscription.sms') }}:</span>
                    <span> {{ messageBalance.sms.chargeable }} </span>
                  </div>
                  <div class="flex justify-between mt-1">
                    <span>{{ $t('topbar.subscription.whatsapp') }}:</span>
                    <span> {{ messageBalance.whatsapp.chargeable }} </span>
                  </div>
                </div>
              </div>
              <hr class="nv-widget__divider-full">
              <div class="px-4 py-2">
                <i18n
                  path="topbar.subscription.subscription_learn_more"
                  tag="span"
                >
                  <template #here>
                    <a
                      href="https://support.8x8.com/CPaaS/Proactive_Outreach"
                      class="text-blue no-underline"
                    >{{ $t('topbar.subscription.here') }}</a>.
                  </template>
                </i18n>
              </div>
            </div>
            <div
              v-else
              class="px-4 pt-3 pb-2"
            >
              {{ $t('topbar.subscription.subscription_inactive') }}
            </div>
          </div>
          <div
            v-if="selectedDropdown === 'subscription-trial' && isSubscriptionActive() && Object.keys(messageBalance).length"
            class="py-1 text-xs"
          >
            <div class="px-4 pt-3 pb-2">
              <div class="flex justify-between">
                <h4>{{ $t('topbar.subscription.messages_sent') }}</h4>
                <div class="text-grey-darker">
                  {{ formatDate(getCurrentTimeZoneDate(), true) }}
                </div>
              </div>
              <div class="flex flex-col mt-2">
                <div class="flex justify-between">
                  <span>{{ $t('topbar.subscription.sms') }}:</span>
                  <span> {{ messageBalance.sms.chargeable }} </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="selectedDropdown === 'setting'"
            class=""
          >
            <div class="nv-widget__user-info flex justify-between text-xs items-center">
              <div class="nv-widget__user-info-details break-words pr-2 flex flex-col">
                <p
                  v-if="userData.Firstname && userData.Lastname"
                  class="font-semibold break-words mb-2"
                >
                  {{ userData.Firstname }}{{ userData.Lastname }}
                </p>
                <p
                  v-if="userData.Login"
                  :class="!userData.Firstname && !userData.Lastname ? 'font-semibold break-words': ''"
                >
                  {{ userData.Login }}
                </p>
              </div>
              <div
                v-if="!Boolean(userData.IsPostPaid) && userData.AccessLevel === 'A'"
                class="nv-widget__user-info-access rounded-lg px-2 py-1"
              >
                Admin
              </div>
            </div>
            <hr class="nv-widget__divider-full">
            <a
              v-if="Object.keys(userData).length"
              class="nv-widget__link text-xs"
              href
              @click.prevent="$emit('click-whats-new')"
            >
              {{ $t('app_labels.whats_new') }} ?
            </a>

            <a
              v-if="Object.keys(userData).length"
              class="nv-widget__link text-xs"
              href
              @click.prevent="$emit('select-option', 'settings')"
            >
              {{ $t('user.top_menu[1]') }}
            </a>
            <a
              v-if="Object.keys(userData).length && enableManageUser"
              class="nv-widget__link text-xs"
              href
              @click.prevent="$emit('select-option', 'usermanagement')"
            >
              {{ $t('user.top_menu[2]') }}
            </a>
            <div class="nv-widget__user-info-language flex justify-between items-center">
              <span class="block mb-1 text nv-widget__link text-xs">{{ $t('user.top_menu[3]') }} </span>
              <span class="block">
                <el-popover
                  placement="left"
                  width="220"
                  trigger="click"
                  popper-class="lang-popover"
                  :visible-arrow="false"
                >
                  <span
                    slot="reference"
                    class="nv-widget__link text-xs"
                  >
                    <span :class="`flag-icon flag-icon-${getFlag(selectedLocale)} flag-icon-squared`" />
                    <span class="font-semibold text-xs mt-1">
                      {{ getLocaleName(selectedLocale) }}
                      <i class="icon-novo-arrow-right ml-1" />
                    </span>
                  </span>
                  <div>
                    <a
                      v-for="i in getLocales()"
                      :key="i.code"
                      class="no-underline truncate p-2 py-1 block text-xs nv-lang-menu cursor-pointer"
                      :class="{
                        'text-grey cursor-not-allowed': !Boolean(i.enable),
                        'selected': i.code === selectedLocale
                      }"
                      :disabled="!Boolean(i.enable)"
                      @click.prevent="() => {
                        if (!Boolean(i.enable)) return;
                        selectLocale(i.code)
                      }"
                    >
                      <span :class="`flag-icon flag-icon-${i.flag} flag-icon-squared`" />
                      {{ i.alias }}
                      <span
                        v-if="!Boolean(i.enable)"
                        class="text-grey text-xs"
                      > ({{ i.coming_soon }}) </span>
                    </a>
                  </div>
                </el-popover>
              </span>
            </div>
            <div class="nv-widget__content flex items-center justify-between">
              <span class="mr-2 nv-widget__link text-xs">{{ $t('user.top_menu[4]') }}</span>
              <div :style="{padding: '8px 16px'}">
                <el-switch
                  v-model="darkMode"
                  class="mr-2"
                  @change="$emit('toggle-theme')"
                />
              </div>
            </div>
            <hr class="nv-widget__divider-full">
            <div class="text-right py-4 px-4">
              <a
                class="nv-widget__link nv-widget__link--logout text-xs rounded"
                @click.prevent="$emit('select-option', 'logout')"
              >
                <i class="icon-novo-leave mr-1" />
                {{ $t('user.top_menu[5]') }}
              </a>
            </div>
          </div>
          <div
            v-else-if="selectedDropdown === 'help'"
            class="flex flex-col"
          >
            <a
              href="/support"
              rel="noopener noreferrer nofollow"
              class="nv-widget__link text-xs flex justify-between items-center"
            >
              <span class="block">{{ $t('user.top_menu[6]') }}</span>
              <span class="icon-novo-arrow-right" />
            </a>
            <a
              rel="noopener noreferrer nofollow"
              href="https://developer.8x8.com/connect/reference#getting-started-with-sms-api"
              class="nv-widget__link text-xs flex justify-between items-center"
              target="_blank"
            >
              <span class="block">{{ $t('user.top_menu[7]') }}</span>
              <span class="icon-novo-expand block" />
            </a>
            <a
              href="https://status.wavecell.com/"
              rel="noopener noreferrer nofollow"
              class="nv-widget__link text-xs flex justify-between items-center"
              target="_blank"
            >
              <span class="block">{{ $t('user.top_menu[8]') }}</span>
              <span class="icon-novo-expand block" />
            </a>
            <a
              href="https://wavecell.com/about-us/"
              rel="noopener noreferrer nofollow"
              class="nv-widget__link text-xs flex justify-between items-center"
              target="_blank"
            >
              <span class="block">{{ $t('user.top_menu[9]') }}</span>
              <span class="icon-novo-expand block" />
            </a>
          </div>
        </nav>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import languages from '../../../locale/lang/languages.json';
import { handlePackageLimit } from '../../common/handle-package-limit';

export default {
  props: {
    userData: {
      type: Object,
      default: () => {},
    },

    balance: {
      type: Object,
      default: () => {},
    },
    enableManageUser: {
      type: Boolean,
      default: false,
    },
    isShowOtp: {
      type: Boolean,
      default: false,
    },

    hasFreeCredits: {
      type: Boolean,
      default: false,
    },

    isShowBalance: {
      type: Boolean,
      default: false,
    },

    darkMode: {
      type: Boolean,
      default: false,
    },

    isImpersonation: {
      type: Boolean,
      default: false,
    },

    oxygenMode: {
      type: Boolean,
      default: false,
    },

    isShowOxygenToggleBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let hasSubscription;
    let isSmsOnboardingStatusTrial;
    try {
      const user = JSON.parse(localStorage.getItem('CPV3_User'));
      hasSubscription = user.Subscription.Status;
      isSmsOnboardingStatusTrial = user.OnboardingStatusSms === 'TRIAL';
    } catch (e) {
      // do nothing
    }

    return {
      isOpen: false,
      selectedDropdown: '',
      selectedLocale: localStorage.getItem('SELECTED_LOCALE') || 'en',
      subscriptionPackage: {},
      messageBalance: {},
      hasSubscription,
      isSmsOnboardingStatusTrial,
    };
  },

  computed: {
    formatBalance() {
      return (val) => {
        const d = this.$constants.DECIMAL_PLACES;
        const l = this.$constants.LOCALE;

        if (!val) { return '0.00'; }

        return this.$helper.formatPrice(val, d, l);
      };
    },
  },

  watch: {
    userData(newVal) {
      if (newVal.Subscription) {
        if (newVal.Subscription.Package && !Array.isArray(newVal.Subscription.Package)) {
          // eslint-disable-next-line prefer-destructuring
          this.subscriptionPackage = newVal.Subscription.Package.subscriptions[0];
        } else {
          this.subscriptionPackage = newVal.Subscription.Package;
        }
      }

      if (this.isSubscriptionActive()) {
        this.getBalance();
      }
    },

  },


  methods: {
    ...mapActions({
      getMessageBalance: 'common/getMessageBalance',
    }),

    getUserAlias(userData) {
      if (Object.keys(userData).length) {
        if (userData.Firstname && userData.Lastname) {
          const firstName = userData.Firstname.trim().charAt(0);
          const lastName = userData.Lastname.trim().charAt(0);

          return `${firstName}${lastName}`.toUpperCase();
        }

        if (userData.Firstname && !userData.Lastname) {
          return userData.Firstname.trim().charAt(0).toUpperCase();
        }

        if (!userData.Firstname && userData.Lastname) {
          return userData.Lastname.trim().charAt(0).toUpperCase();
        }

        if (userData.Login) {
          return userData.Login.charAt(0).toUpperCase();
        }
      }

      return '';
    },

    getCurrentTimeZoneDate() {
      return moment().utcOffset(this.userData.TimeZoneGMTOffset / 60).format();
    },

    hasSupportEntitlement() {
      return handlePackageLimit('cp-support');
    },

    getNavClassBySelectedWidget(selectedWidget = '') {
      let navClass = 'rounded shadow';
      switch(selectedWidget) {
        case 'subscription':
          navClass = `${navClass} nv-widget__dropdown-subscription`;
          if (this.isSmsOnboardingStatusTrial) navClass = `${navClass} in-trial`;
          if (this.hasSupportEntitlement()) navClass = `${navClass} has-support`;
          break;
        case 'subscription-trial':
          navClass = `${navClass} nv-widget__dropdown-subscription-trial`;
          if (this.hasSupportEntitlement()) navClass = `${navClass} has-support`;
          break;
        case 'help':
          navClass = `${navClass} nv-widget__dropdown help-dropdown`;
          break;
        default:
          navClass = `${navClass} nv-widget__dropdown user-dropdown`;
      }
      return navClass;
    },

    isSubscriptionActive() {
      if (Array.isArray(this.subscriptionPackage)) {
        return false;
      }

      const { status } = this.subscriptionPackage;

      return status === 'ACTIVE';
    },

    openWidgetDropdown(selectedWidget = '') {
      if (['subscription', 'subscription-trial'].includes(selectedWidget)) {
        this.getBalance();
      }

      if (this.isOpen) {
        if (this.selectedDropdown !== selectedWidget) {
          this.selectedDropdown = selectedWidget;
        } else {
          this.closeWidgetDropdown();
        }
        return;
      }

      this.selectedDropdown = selectedWidget;
      this.isOpen = true;
    },

    closeWidgetDropdown() {
      this.selectedDropdown = '';
      this.isOpen = false;
    },

    selectLocale(code) {
      this.selectedLocale = code;
      localStorage.setItem('SELECTED_LOCALE', this.selectedLocale);

      // this.$i18n.locale = this.selectedLocale;
      if (window.analytics) {
        let userData = {};
        try {
          userData = JSON.parse(localStorage.getItem('CPV3_User'));
        } catch (e) {
          // do nothing;
        }

        window.analytics.track('New Locale Selected', {
          Email: userData.Email,
          SelectedLocale: this.selectedLocale,
        }, () => {
          window.location.href = window.location.href;
        });
      } else {
        window.location.href = window.location.href;
      }
      return false;

      // const event = new Event('localeChanged');
      // window.dispatchEvent(event);
    },

    getLocaleName(selected) {
      const s = languages.find(v => v.code === selected);
      return s.alias;
    },

    getFlag(selected) {
      const s = languages.find(v => v.code === selected);
      return s.flag;
    },


    getLocales() {
      return languages;
    },

    getBalance() {
      const { AccountUid: accountUid } = this.userData;
      if (!this.subscriptionPackage) {
        this.messageBalance = {
          sms: { chargeable: 0 },
          whatsapp: { chargeable: 0 },
        };
      } else if (accountUid) {
        this.getMessageBalance({ accountUid, timeZoneOffset: 0 })
          .then(async (data) => {
            this.messageBalance = data;
          })
          .catch((err) => {
            this.$showError(this, err);
          });
      }
    },

    formatDate(date, noDay) {
      const newDate = date ? new Date(date) : new Date();
      const day = newDate.toLocaleString('default', { day: 'numeric' });
      const month = newDate.toLocaleString('default', { month: 'long' });
      const year = newDate.getFullYear();

      return noDay ? `${month} ${year}` : `${month} ${day}, ${year}`;
    },

    getEntitlementValue(entitlements, featureId) {
      const entitlement = entitlements.find(e => e.feature.id === featureId);

      if (!entitlement) {
        return 0;
      }
      return entitlement.usageLimit;
    },

  },
};
</script>

<style lang="scss">
.loading-anim {
  width: 103px;
  color: var(--text);

  &:after {
    content: '';
    display: inline-block;
    animation: ellipsisAnimation steps(1, end) 1s infinite;
  }
}

@keyframes ellipsisAnimation {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
</style>

<style>
.widget-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}

.nv-widget__balance .text-balance,
.nv-widget__balance .text-balance-label {
  color: var(--text-color);
}

.nv-widget__balance .top-up-btn  {
 color: var(--border-color);
}

.nv-widget__balance .top-up-btn {
  background-color: var(--background-gray);
}
.nv-widget__balance .top-up-btn:hover {
  text-decoration: none;
  background-color: var(--background-gray-highlight);
}
.nv-widget__user-profile:focus {
  border: solid 1px var(--blue);
}

.nv-widget__user-profile:hover, .nv-widget__user-profile.active {
  background-color: #CACACA
}

.nv-widget__user-profile {
  background-color: var(--background-light)
}

.nv-toggle-oxygen:hover {
  background-color: var(--background-light);
}

.nv-toggle-oxygen, .nv-toggle-oxygen .nv-toggle-oxygen__label, i.nv-widget__user-icons {
  color: var(--text-color);
}

.nv-widget__user  {
  font-size: 24px;
  color: var(--text-color);
}

.nv-widget__user .nv-widget__user-profile {
  width:24px;
  height: 24px;
}

.nv-widget__user-box {
  border-color: var(--background-light);
}

.nv-widget__button {
  padding: 8px 8px 6px;
  color: var(--gray-darkest);
  background-color: var(--white);
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color var(--ease);
  background-color: var(--ease);
}

.nv-widget__button--capitalize {
  padding: 5px 5px 3px;
  text-transform: uppercase;
  font-weight: 500;
}

.nv-widget__button:hover,
.nv-widget__button--active
{
  color: var(--white);
  background-color: var(--gray-darker);
}

.nv-widget__content {
  display: block;
}

.nv-widget__text {
  position: relative;
}

.nv-widget__link {
  padding: 8px 12px;
  color: var(--text-color);
  text-decoration: none;
  display: block;
  cursor: pointer;

  transition: color var(--ease);
}

.nv-widget__link:hover {
  color: var(--text-color);
  text-decoration: none
}

.nv-widget__link.nv-widget__link--danger {
  color: var(--red);
};

.nv-widget__divider {
  margin: 5px;
  border-top: solid 1px var(--text-color);
  opacity: 0.3;
}

.nv-widget__subscription {
  background-color: var(--white);
}

.nv-widget__divider-full {
  margin: 0;
  border-top: solid 1px var(--text-color);
  opacity: 0.1;
}

.nv-widget__dropdown {
  min-width: 150px;
  max-width: 320px;
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  outline: none;
  color: var(--text-color);
  background-color: var(--background);
  transform-origin: top;
  transition: color var(--ease);
}

.nv-widget__dropdown.user-dropdown {
  right: 30px;
  width: 320px;
}

.nv-widget__dropdown.help-dropdown {
  right: 70px;
  width: 200px;
}

.nv-widget__dropdown-subscription {
  /* padding: 10px 0; */
  /* padding: 10px; */
  min-width: 350px;
  max-width: 400px;
  position: absolute;
  top: calc(100% + 2px);
  right: 90px;
  outline: none;

  color: var(--text-color);
  background-color: var(--background);
  transform-origin: top;

  transition: color var(--ease);
}

.nv-widget__dropdown-subscription.has-support {
  right: 145px;
}

.nv-widget__dropdown-subscription.in-trial {
  right: 213px;
}

.nv-widget__dropdown-subscription.in-trial.has-support {
  right: 270px;

}

.nv-widget__dropdown-subscription-trial {
  min-width: 350px;
  max-width: 400px;
  position: absolute;
  top: calc(100% + 2px);
  right: 90px;
  outline: none;

  color: var(--text-color);
  background-color: var(--background);
  transform-origin: top;

  transition: color var(--ease);
}

.nv-widget__dropdown-subscription-trial.has-support {
  right: 145px;
}

.nv-widget__subscription-trial {
  background-color: var(--background);
  transition: background-color var(--ease);
}

.nv-widget__subscription-trial:hover {
  background-color: var(--background-light);
  border-radius: 8px;
}

.nv-widget__user-info {
  padding: 16px 12px;
}

.nv-widget__user-info-details {
  color: var(--text-color)
}

.nv-widget__user-info-access {
  color: var(--white);
  background-color: #666666;
}

.nv-widget-fade-enter-active,
.nv-widget-fade-leave-active {
  transition: opacity var(--ease),
  transform var(--ease);
}

.nv-widget-fade-enter,
.nv-widget-fade-leave-to {
  opacity: 0;
  transform: scaleY(0.8);
}

.no-underline {
  text-decoration: none !important;
}

.subscription-header > * {
  margin-right: 10px;
}

.subscription-header .el-progress-circle {
  height: 20px !important;
  width: 20px !important;
}

.subscription-header .el-progress__text {
  display: none;
}

.subscription-note {
  color: #A3A3A3;
}

.subscription-header .el-divider--vertical {
  height: 1.5em !important;
}

.subscription-header__label, .arrow-label {
  color: var(--text-color);
}

.subscription-month {
  display: flex;
  align-items: flex-end !important;
  justify-content: end !important;
}

.subscription-status.active {
  background-color: var(--green-light)
}

.subscription-status.inactive {
  background-color: var(--red-light)
}

.subscription-status.trial {
  /* background-color: var(--yellow-light); */
  background-color: #fcdf6c;
}

.subscription-status.active .subscription-status__label {
  color: var(--text-color)
}

.subscription-opened, .subscription-trial-opened, .nv-lang-menu:hover, .nv-lang-menu.selected {
  background-color: var(--background-light) !important;
}

.nv-lang-menu {
  color: var(--text-color);
}

.nv-widget__link--logout {
  background-color: var(--background-gray);
  color: var(--border-color);
  display: inline;
}

.nv-widget__link--logout:hover {
  color: var(--border-color);
  background-color: var(--background-gray-highlight);
}

.lang-popover {
  border: none !important;
  background-color: var(--background) !important;
}

</style>
