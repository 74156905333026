/* eslint-disable func-names */
import $store from '../store';

const fn = {
  /**
    * Return formated price
    * @param {Number} n -> number/value
    * @param {Number} d -> max decimal places
    * @param {String} l -> locale
    * @return {String} formatted price
  * */
  formatPrice(n, d = 7, l = 'en-US') {
    if (Number.isNaN(n)) return '0.00';

    const num = Number(n);
    const maximumFractionDigits = d;

    let ns = num.toLocaleString(l, { maximumFractionDigits });

    if (ns.split('.').length < 2) {
      ns = `${ns}.00`;
    }

    return ns;
  },

};

const Wc = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global wc instance
        this.$helper = fn;
      },

      methods: {
        async $getUserBalance(user) {
          const isShowBalance = Boolean(user.Flag_ShowBalance) && user.AccessLevel === 'A';
          // get balance
          if (isShowBalance) {
            try {
              await $store.dispatch('common/getBalance');
            } catch (e) {
              this.$showError(this, e);
              // do nothing
            }
          }
        },
      },
    });

    Vue.prototype.helper = fn; // eslint-disable-line

    const ctx = new Vue();
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$getUserBalance = function (user) {
      ctx.$getUserBalance(user);
    };
  },
};

export default Wc;
