//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Moment from 'moment';

export default {
  name: 'AuthCard',

  props: {
    defaultValue: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    recommend: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
  },

  computed: {
    enabled() {
      return this.data && this.data.enabled;
    },
  },

  created() {
    this.defaultValue = this.data ? this.data.default : false;
  },

  methods: {
    getAuthName(authName) {
      let name = authName;
      switch (authName) {
      case 'AUTHENTICATOR_APP':
        name = 'Google';
        break;
      case 'SMS_OTP':
        name = 'SMS';
        break;
      default:
        break;
      }
      return name;
    },

    remove() {
      this.$emit('remove');
    },

    toggleDefault() {
      const payload = { type: this.data.type };
      this.$emit('set-default', payload);
    },

    enable() {
      this.$emit('enable');
    },

    formatDate(dt) {
      return Moment(dt).format('DD MMM YYYY');
    },
  },
};
