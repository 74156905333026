//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { VueTelInput } from 'vue-tel-input';

import CodeInput from './CodeInput.vue';
import 'mobile-identity';

export default {
  name: 'AuthSms',

  components: {
    CodeInput,
    VueTelInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    generateOtp: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const validatePhone = (rule, value, callback) => {
      if (this.validPhone && value.trim().length) {
        callback();
      } else {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.phone_number') })));
      }
    };

    const self = this;

    return {
      otpLoading: false,
      timer: 120, // seconds
      timerId: null,
      counter: 0,
      resendable: true,
      isAuthSdKEnabled: false,

      validPhone: false,

      form: {
        uid: '',
        token: '',
        phoneNumber: '',
      },

      clientToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJBY2NvdW50SWQiOiJTREtfMUQzMEQiLCJlbnYiOiJQUk9EIiwiZG9tYWluIjoiY29ubmVjdC44eDguY29tIiwiYWNjZXNzS2V5SWQiOiJEMjBFM0I2Ny1FNjdBLUVGMTEtQjk0RC0wNjBENkZGM0UyRjIiLCJpYXQiOjE3MjcyMzgxMTYsImV4cCI6MTcyNzI0MTcxNiwiaXNzIjoiU0RLXzFEMzBEIn0.HlRGUsNrI9S5cAOPMMsUwI3Tmdf7Mmo4OJOfO1G2Vbkaxr80tqPMdSW1kE798YqtXW55TPAIlvnjwCRkpo6zXG0KniecIduR1-48KHrxTdXQqzToYy9h1o3FTCBijCFuCZSqS9m1wJATrPHXS6OaTFv51cqW2yYUQiiRUljZ0ND8caE1241DUfBSebLXuQfprSO4ngjxpJQU3DuuFviggZcz0exVTr7N26OC7L9fWDsDjuwcWcxf-h_kiSdd7a2hkQqv3GsEUmaqzMNNSpMR6JaR-27z5VDxln42HMTWjK1qX3pW6FhuMfhpk07P6cLRTEONG4HG485y2HvOohNVA6MDAYI_vZLS_NXH_qQHxkpOLtorc9zEwHzx85MU6XBiEQgm9eJa4ADplS7zYKPMMNZT5szJIqdyYpE3RYPGXyuWCvw7zu23vxIL_pcjav3jIkSyBu21s7LOC3fwoIeDd8KybCpK8mZK14HyU59-Y_He5LyABDuBza-omdrVbUPyE46z_lJbUedRuslxmy0yprcjhoEp96t6GUqgtmv_14DelRUuQlB6JhAH9kh5U0VULg9cYJho-C-ZW190uYysrd68xXjqwKPJ0QFrs0ptdujm6CUl8o2NoFmOK44tH0wTZhpudDRAQK4pZqv7FY024BG3IBgZ6bnUXDceORL9C-Q",
      clientKey: "D20E3B67-E67A-EF11-B94D-060D6FF3E2F2",

      formRules: {
        token: [
          { required: true, message: self.$t('validations.valid', { value: this.$t('fields.code') }), trigger: 'blur' },
        ],
        phoneNumber: [
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  created() {
    this.checkAuthSdk();
  },

  watch: {
    step(value) {
      this.form.token = '';

      // Clear timer from event loop queue
      if (this.timerId && value !== 2) {
        clearTimeout(this.timerId);
      }
    },
  },

  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },

  methods: {
    ...mapActions({
      enableSMS2FA: 'account/enableSMS2FA'
    }),
    changeNumber() {
      this.$emit('update-step', 1);
    },

    handlePhoneValidate(n, validator) {
      this.validPhone = validator.valid;
      this.$refs.form.validateField('phoneNumber');
    },

    handleOnBlurPhone() {
      this.$refs.form.validateField('phoneNumber');
    },

    checkAuthSdk() {
      const data = JSON.parse(localStorage.getItem('CPV3_User'));
      this.isAuthSdKEnabled = (data && data?.Features?.authSDK) || data.BetaFeatures.includes('WEB_Auth_SDK_V1');
    },

    cancel() {
      this.$emit('update-step', 1);
      this.$emit('back');
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const payload = { type: 'SMS_OTP', ...this.form };
            this.$emit('submit', payload);
          } else {
            return false;
          }
          return false;
        });
      }
    },

    countdown() {
      if (this.counter > 0) {
        this.timerId = setTimeout(() => {
          this.counter -= 1;
          this.countdown();
        }, 1000);
      } else {
        this.resendable = true;
      }
    },

    otpVerifySuccess(response) {
      const { destination: msisdn = '', status } = response?.detail || {};

      if (status !== 'VERIFIED' && !destination) {
        this.$message.error('Failed to activate SMS 2FA.');
        return;
      }

      this.enableSMS2FA({
        msisdn,
      })
      .then(response => {
        if (response) {
          this.$message.success('Setup SMS 2FA successful.');
        }
        this.$emit('update-step', 1);
        this.$emit('back');
      })
      .catch(err => {
        this.$message.error('Failed to verify SMS.');
      })
    },

    resendCode() {
      if (this.resendable) {
        this.counter = this.timer;
        this.countdown();
        this.sendCode();
        this.resendable = false;
      }
    },

    sendCode() {
      this.resendable = true;
      this.otpLoading = true;

      const pn = parsePhoneNumber(this.form.phoneNumber);
      const payload = {
        ...this.form,
        country: pn.regionCode,
      };

      // execute first recaptcha
      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_2fa_otp'))
        .then((token) => {
          payload.gToken = token;

          return this.generateOtp(payload);
        }).then((res) => {
          this.form.uid = res.uid;
          this.$emit('update-step', 2);
        })
        .catch((err) => {
          this.qrCode = '';
          // this.$message.error('Failed to send OTP code');
          this.$showError(this, err);
        })
        .finally(() => {
          this.otpLoading = false;
        });
    },
  },
};
