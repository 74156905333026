import Vue from 'vue';
import VueRouter from 'vue-router';
import singleSpaVue from 'single-spa-vue';
import ElementUI, { Message } from 'element-ui';
import singleSpa from 'single-spa';
import jwtDecode from 'jwt-decode';
import { sync } from 'vuex-router-sync';
import VueI18n from 'vue-i18n';
import VueTelInput from 'vue-tel-input';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueCookies from 'vue-cookies';

import 'vue-tel-input/dist/vue-tel-input.css';

// import enLocale from 'element-ui/lib/locale/lang/en';
// import idLocale from 'element-ui/lib/locale/lang/id';
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
// import ElementLocale from 'element-ui/lib/locale';
import ErrorUIHandler from '../common/handle-error-msg';
import VueJstz from './plugins/vue-jstz';
import VueZxcvbn from './plugins/vue-zxcvbn';
import WcHelpers from './plugins/vue-wc';
import Nav from './Nav.vue';
import store from './store/index';
import Constants from './constants/index';
import http from '../../utils/http';
import lang from '../../utils/lang';

// import en from '../../locale/lang/en.json';
// import id from '../../locale/lang/id.json';

import env from '../../utils/process_env';

import handleRoutes from '../common/handle-routes';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue);
}

Vue.use(VueI18n);
Vue.use(VueTelInput);
Vue.use(VueCookies, { expires: '3y' });

const locale = lang.getSelectedLanguage() || lang.getDefaultLanguage();

document.querySelector('html').setAttribute('lang', locale);

const i18n = new VueI18n({
  locale, // set locale
  fallbackLocale: lang.getDefaultLanguage(),
});

Vue.use(Constants);
Vue.use(VueJstz);
Vue.use(VueZxcvbn);
Vue.use(WcHelpers);
Vue.use(VueRouter);

// Vue.use(VueReCaptcha, { siteKey: env.googleRecaptchaKey });

// Custom detect click outside directive
let handleOutsideClick = null;
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          if (excludedEl) {
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        }
      });
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

const router = new VueRouter({
  base: '/',
  mode: 'history',
});

sync(store, router);

const showMessage = (message, type = 'warning') => {
  Message({
    message,
    type,
    // onClose: () => singleSpa.navigateToUrl('/'),
  });
};

const removeAuthInLocalStorage = () => {
  localStorage.removeItem('WWW-Authenticate');
  localStorage.removeItem('cpv3Impersonate');
  localStorage.removeItem('CPV3_User');
  localStorage.removeItem('passwordResetSkipped');
  localStorage.removeItem('user_country');
  sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
};

router.beforeEach((to, from, next) => {
  if (window.location.pathname.match(/^\/login/i)) {
    // Dont run this if path is in /login
    if (to.path.includes('/login')) {
      return 0;
    }
    next();
  }

  const authToken = localStorage.getItem('WWW-Authenticate');
  const impersonate = Boolean(localStorage.getItem('cpv3Impersonate'));

  if (authToken) {
    if (impersonate) {
      const { exp = 0 } = jwtDecode(authToken);
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime > exp) {
        showMessage('Token is expired', 'error');

        // remove existing WWW-Authenticate
        localStorage.removeItem('WWW-Authenticate');
        localStorage.removeItem('cpv3Impersonate');
        localStorage.removeItem('CPV3_User');
        singleSpa.navigateToUrl('/login');
        return 0;
      }
    }
    http.v1.get('auth/check?rolesVersion=2')
      .then((d) => {
        const { data } = d;
        handleRoutes(data, to, singleSpa, next);
      })
      .catch((err) => {
        if (typeof err === 'object') {
          if (window.Bugsnag) {
            window.Bugsnag.notify(err);
          }
          if ((err.code && err.code === 401)
            || (err.statusCode && err.statusCode === 401)
            || (err.body && err.body.code && err.body.code === 401)
            || (err.response && err.response.statusCode && err.response.statusCode === 401)
            || (err.response && err.response.status && err.response.status === 401)
            || (err.status && err.status === 401)) {
            removeAuthInLocalStorage();
            showMessage('Invalid credentials or token has been expired.', 'error');
            singleSpa.navigateToUrl('/login');
          }
        }
      });
  } else {
    const redirect = to.path === '/' ? '/login' : `/login?redirect=${to.path}`;
    singleSpa.navigateToUrl(redirect);
  }

  return 0;
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#navbar',
    i18n,
    store,
    router,
    render(h) {
      // check root config for locale messages
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      Vue.use(VueReCaptcha, { siteKey: env.googleRecaptchaKey });

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      const {
        localeMessages, langUtil, appConfig, telemetry,
      } = this;
      Vue.prototype.$telemetry = telemetry;

      Vue.use(ErrorUIHandler);

      // Assign appConfig so it can be accessible to all components
      Vue.prototype.appConfig = appConfig;

      return h(Nav, { props: { localeMessages, langUtil, appConfig } });
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
