//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Promise } from 'es6-promise';
import _ from 'lodash';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
// import singleSpa from 'single-spa';
import Moment from 'moment';
import http from '../../utils/http';
import Settings from './Settings.vue';
import AuthGoogle from './partials/AuthGoogle.vue';
import AuthHelp from './partials/AuthHelp.vue';
import AuthSms from './partials/AuthSms.vue';
import AuthSuggestEnable from './partials/AuthSuggestEnable.vue';
import Dropdown from './partials/Dropdown.vue';
// import FreeCreditsOtp from './partials/FreeCreditsOtp.vue';
import Logo from './partials/Logo.vue';
import Widgets from './partials/Widgets.vue';
import WAInfoDialog from './partials/WAInfoDialog.vue';
import WAPopover from './partials/WAPopover.vue';
// import handleErrors from '../common/handle-api-errors';

import appRoutes from './json/appRoutes.json';
import { handlePackageLimit } from '../common/handle-package-limit';

export default {
  name: 'App',

  components: {
    Settings,
    AuthGoogle,
    AuthSms,
    AuthHelp,
    AuthSuggestEnable,
    Logo,
    Dropdown,
    Widgets,
    WAPopover,
    WAInfoDialog,
    // FreeCreditsOtp,
  },

  data() {
    const self = this;

    return {
      activeRoute: '/',
      appRoutes,
      hasSenderIdAccess: false,
      hasVerif8Enabled: false,
      onboardVerif8: false,
      suggestEnableAuth: false,
      verificationLoading: false,
      validationStep: 1,
      stepsHeader: {
        sms: [
          {
            title: self.$t('user.sms_verification_steps[0].title'),
            text: self.$t('user.sms_verification_steps[0].text'),
          },
          {
            title: self.$t('user.sms_verification_steps[1].title'),
            text: self.$t('user.sms_verification_steps[1].text'),
          },
        ],
      },

      modalScreen: null,
      authTokenKey: 'WWW-Authenticate',
      name: 'User',
      needMigrationFromV2: false,
      userId: '',
      accountId: '',
      login: '',
      authUserMangement: false,

      hasPaymentAccess: false,
      // hasOldCPAccess: false,
      hasFreeCredits: false,
      userCountryCode: '',
      isUserCountryWhiteListed: false,

      showProfileDialog: false,
      resetForm: false,
      tab: 'profile',
      isAdmin: false,
      isFetching: true,
      userData: {},

      darkMode: false,
      compactSidebar: false,
      oxygenMode: true,

      openedAppMenu: '',

      passwordExpiring: false,
      passwordExpired: false,
      passwordResetSkipped: null,
      showPasswordExpiring: false,

      showOTP: false,
      isModalShow: false,
      isShowBalance: false,
      isImpersonation: Boolean(localStorage.getItem('cpv3Impersonate')),

      loading: false,
      prevUrl: null,
      supportEmail: this.appConfig.supportEmail,
      enforce2fa: false,
      show2faSetting: false,
      messageBalance: {},
      waPopoverVisible: false,
      waDialogVisible: false,
      isNewToWhatsApp: false,
      hasDoneWARequest: false,
      hasAuthSDKAccess: false,
      hideCost: false,
    };
  },

  computed: {
    ...mapGetters({
      getTwoFA: 'account/getTwoFA',
      balance: 'common/balance',
      whiteListedCountries: 'common/whitelistedCountries',
    }),

    ...mapState('common', ['timezones']),

    formatBalance() {
      return (val) => {
        const d = this.$constants.DECIMAL_PLACES;
        const l = this.$constants.LOCALE;

        if (!val) {
          return '0.00';
        }

        return this.$wc.formatPrice(val, d, l);
      };
    },
  },

  beforeDestroy() {
    this.$el.className = '';

    // Remove single-spa custom event
    window.removeEventListener(
      'single-spa:routing-event',
      this.singleSpaRouteListener,
    );
  },

  created() {
    // detect user location
    this.fetchUserCountry();

    // Opens the app drawer if any subapp is active on load
    this.expandAppDrawer();

    // Listen to single-spa custom even for app changes
    window.addEventListener(
      'single-spa:routing-event',
      this.singleSpaRouteListener,
      false,
    );
    window.addEventListener('fetchUserData', this.fetchUpdatedData);

    // Set theme default
    const { darkMode: defaultMode } = this;
    const defaultValue = defaultMode || 0;
    const darkMode = localStorage.getItem('theme-dark');
    const isDarkMode = parseInt(darkMode || defaultValue, 10);
    this.toggleDarkMode(isDarkMode);

    // Set sidebar state default
    const { compactSidebar: defaultSidebar } = this;
    const defaultSidebarValue = defaultSidebar || 0;
    const compactSidebar = localStorage.getItem('compact-sidebar');
    const isCompactSidebar = parseInt(
      compactSidebar || defaultSidebarValue,
      10,
    );
    this.toggleSidebar(isCompactSidebar);

    this.oxygenMode = (Boolean(this.$cookies.isKey('x-source') && this.$cookies.get('x-source') === 'oxygen'));

    let authToken = localStorage.getItem('WWW-Authenticate');

    window.addEventListener('navigateToMain', () => this.$router.push('/'));
    window.addEventListener('navigateToMCS', () => this.$router.push('/multichannel-sender'));

    if (authToken) {
      this.loading = true;
      this.fetchData();
    }

    // if authToken is not found, retry after 1 sec
    if (!authToken) {
      setTimeout(() => {
        authToken = localStorage.getItem('WWW-Authenticate');

        if (authToken) {
          this.fetchData();
        } else {
          console.log("Token not found."); // eslint-disable-line
        }
      }, 1000);
    }

    window.addEventListener('userUpdated', () => {
      const user = localStorage.getItem('CPV3_User');

      if (user) {
        try {
          this.userData = JSON.parse(user) || {};
          this.userProfile = JSON.parse(user) || {};

          this.name = this.userData.Firstname || this.userData.Lastname
            ? `${this.userData.Firstname || ''} ${this.userData.Lastname || ''
            }`
            : this.userData.Login;

          this.isAdmin = this.userData.AccessLevel === 'A';
        } catch (e) {
          // do nothing
        }
      }
    });

    window.addEventListener('account', (e) => {
      this.openSettings(e.type);
    });

    window.addEventListener('profile', (e) => {
      this.openSettings(e.type);
    });

    window.addEventListener('preference', (e) => {
      this.openSettings(e.type);
    });

    window.addEventListener('open:settings', (event) => {
      if (event.detail) {
        const { tab } = event.detail;
        this.openSettings(tab || 'profile');
      }
    });

    window.addEventListener('clearNav', () => {
      _.forEach(this.$refs, (el, key) => {
        if (this.$refs[key] && this.$refs[key].$el) {
          this.$refs[key].$el.className = 'relative flex items-center h-full px-6 py-3 text-grey-dark no-underline text-sm border-r router-link-exact-active';
        }
      });
    });

    window.addEventListener('updateBalance', () => {
      this.getBalance();
    });

    window.addEventListener('verif8:success-onboarding', () => {
      this.expandAppDrawer();
    });
  },

  methods: {
    ...mapMutations({
      setEnforce2FA: 'account/SET_ENFORCE_2FA',
    }),

    ...mapActions({
      updateTwoFA: 'account/updateTwoFA',
      enableTwoFa: 'account/enableTwoFa',
      getTwoFaQrCode: 'account/getTwoFaQrCode',
      getTwoFaOtpCode: 'account/getTwoFaOtpCode',
      getTimezones: 'common/getTimezones',
      getBalance: 'common/getBalance',
      getAuthUser: 'common/getAuthUser',
      getWhitelistedCountries: 'common/getWhitelistedCountries',
      getUserCountry: 'common/getUserCountry',
      updateCachedUser: 'common/updateCachedUser',
      enforce2FA: 'account/getEnforce2FA',
      getUserCacheData: 'common/getUserCacheData',
      getMessageBalance: 'common/getMessageBalance',
      getWhatsAppChannels: 'common/getWhatsAppChannels',
      getWhatsAppTemplates: 'common/getWhatsAppTemplates',
    }),

    ...mapMutations({
      setEnforce2FA: 'account/SET_ENFORCE_2FA',
    }),

    isPackageLimit(moduleId) {
      return handlePackageLimit(moduleId);
    },

    getIcon(appName) {
      const icons = {
        SMS: 'icon-novo-message',
        'Sender ID': 'icon-novo-evaluate',
        'Chat apps': 'icon-novo-whatsapp',
        'Video interaction': 'icon-novo-video',
        Verif8: 'icon-novo-mark-unread',
        Voice: 'icon-novo-phone',
      };

      return icons[appName];
    },

    getIconLabel(appName) {
      const self = this;
      const label = {
        SMS: self.$t('sidebar_menu_parent_short.SMS'),
        'Sender ID': self.$t('sidebar_menu_parent_short.SenderId'),
        'Chat apps': self.$t('sidebar_menu_parent_short.MSG'),
        'Video interaction': self.$t('sidebar_menu_parent_short.Video'),
        Verif8: self.$t('sidebar_menu_parent_short.Verif8'),
        Voice: self.$t('sidebar_menu_parent_short.Voice'),
      };

      return label[appName];
    },

    // cp-sms-engage
    isPackageLimit_v2(appname) {
      if (appname.toUpperCase() === 'SMS') return this.isPackageLimit('cp-sms');
      if (appname.toUpperCase() === 'SENDER ID') return this.isPackageLimit('cp-sender-id') && this.hasSenderIdAccess;
      if (appname.toUpperCase() === 'CHAT APPS') return this.isPackageLimit('cp-chatapps');
      if (appname.toUpperCase() === 'VIDEO INTERACTION') return this.isPackageLimit('cp-video');
      if (appname.toUpperCase() === 'VOICE') return this.isPackageLimit('cp-voice');
      if (appname.toUpperCase() === 'VERIF8') return this.checkVerif8Access();
      return true;
    },

    checkVerif8Access(type = 'module') {
      try {
        const user = JSON.parse(localStorage.getItem('CPV3_User'));
        if (!user) return false;

        if (type === 'module' && localStorage.getItem('verif8_dry_run') === 'true') {
          return true;
        } else if (type === 'onboarding' && localStorage.getItem('verif8_dry_run') === 'true') {
          return false;
        }

        if (type === 'onboarding') {
          return user.BetaFeatures.includes('WEB_Verif8_V1') && user.AccessLevel === 'A' && !user.Product_VERIF8;
        }
        return (user.BetaFeatures.includes('WEB_Verif8_V1') && user.AccessLevel === 'A' && user.Product_VERIF8);
      } catch (e) {
        // do nothing
        return '';
      }
    },


    async getUserBalance(user) {
      this.isShowBalance = Boolean(user.Flag_ShowBalance) && user.AccessLevel === 'A';
      // get balance
      if (this.isShowBalance) {
        try {
          const v = await this.getBalance();
          localStorage.setItem('balance_curr', v.Currency);
        } catch (err) {
          this.$showError(this, err);
          localStorage.removeItem('balance_curr');
          throw err;
        }
      }
    },

    async fetchUserCountry() {
      try {
        const uc = localStorage.getItem('user_country') || '';

        if (!uc) {
          const v = await this.getUserCountry();
          localStorage.setItem('user_country', v);
        }
      } catch (e) {
        localStorage.removeItem('user_country');
      }
    },

    expandAppDrawer() {
      const activeAppMenu = Object.keys(this.appRoutes).find(appName => window.location.pathname.includes(this.appRoutes[appName].url || -1));
      this.openedAppMenu = activeAppMenu || '';
    },

    singleSpaRouteListener(evt) {
      this.activeRoute = window.location.pathname;
      const user = localStorage.getItem('CPV3_User');
      const currPath = evt.target.location.pathname;

      if (user) {
        try {
          const u = JSON.parse(user);
          if (u) {
            this.prevUrl = currPath;
          }
        } catch (e) {
          // do nothing
        }
      }
    },

    addActiveClass(
      assignedPath = '/',
      className = 'nv-sidebar__link--active shadow',
    ) {
      // Remove first and last slashes
      const path = assignedPath.replace(/^\/|\/$/g, '');

      const activeRoute = this.activeRoute || window.location.pathname;
      let activePath = activeRoute.replace(/^\/|\/$/g, '');

      if (activePath.includes('support/')) {
        activePath = 'support';
      }

      if (activePath.includes('automation/')) {
        activePath = 'automation';
      }

      return path === activePath ? className : '';
    },

    toggleSidebar(isCompact = false) {
      this.compactSidebar = isCompact;

      const rootElem = document.documentElement;
      const COMPACT_SIDEBAR_CLASS = 'compact-sidebar';

      // Toggle sidebar class in body
      if (isCompact) {
        rootElem.setAttribute('data-sidebar', COMPACT_SIDEBAR_CLASS);
      } else {
        rootElem.setAttribute('data-sidebar', '');
      }

      // Update localStorage theme value to current sidebar theme
      localStorage.setItem('compact-sidebar', isCompact ? 1 : 0);
    },

    toggleDarkMode(isDark = false) {
      this.darkMode = isDark;

      const rootElem = document.documentElement;
      const DARK_MODE_CLASS_NAME = 'dark-mode';

      // Toggle dark-mode class in body
      if (isDark) {
        rootElem.setAttribute('data-theme', DARK_MODE_CLASS_NAME);
      } else {
        rootElem.setAttribute('data-theme', '');
      }

      // Update localStorage theme value to current theme value
      localStorage.setItem('theme-dark', isDark ? 1 : 0);
    },

    toggleOxygenTheme() {
      this.oxygenMode = !this.oxygenMode;
      const analyticsEvent = 'SWITCH_TO_OLD_THEME';
      if (window.analytics && Object.keys(this.userData).length) {
        window.analytics.track(
          analyticsEvent,
          {
            Email: this.userData.Email,
          },
          () => {
            window.location.reload(true);
          },
        );
      } else {
        window.location.reload(true);
      }
    },

    disableTwoAuth() {
      return !this.isCountryWhiteListed;
    },

    checkShowSuggestEnableTwoFa() {
      const hideSuggestEnableTwoFa = localStorage.getItem(
        'hideSuggestEnableTwoFa',
      );
      const sessionHideSuggestEnableTwoFa = sessionStorage.getItem(
        'sessionHideSuggestEnableTwoFa',
      );

      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        const user = JSON.parse(cpv3User);

        if (
          !hideSuggestEnableTwoFa
          && !sessionHideSuggestEnableTwoFa
          && !user.TwoFASetting.length
          && user.Needs2FA
          && !user.SSOEnabled
        ) {
          this.modalScreen = 'suggest-enable';
          this.showProfileDialog = true;
          this.isModalShow = true;
        }

        sessionStorage.setItem('sessionHideSuggestEnableTwoFa', true);
      } catch (e) {
        //  do nothing
      }
    },

    setupTwoFa() {
      localStorage.setItem('hideSuggestEnableTwoFa', true);
      this.modalScreen = null;
      this.tab = 'two-factor';
    },

    submit(p) {
      this.verificationLoading = true;
      const payload = { ...p, remember: false };
      // const { type } = payload;
      // const authName = this.getTwoFaAuthName(type);
      this.enableTwoFa(payload)
        .then(() => {
          // remove success message OMG-839
          // this.$message({
          //   message: `Enabled ${authName} two-factor auth`,
          //   type: 'success',
          // });
          this.modalScreen = null;
          this.validationStep = 1;
          this.updateTwoFA();

          // Stop showing suggest 2FA enable once user enables an 2FA
          localStorage.setItem('hideSuggestEnableTwoFa', true);
          this.setEnforce2FA(false);
        })
        .catch((err) => {
          // this.$message.error(err || this.$t('errors.default'));
          this.$showError(this, err);
        })
        .finally(() => {
          this.verificationLoading = false;
        });
    },

    getTwoFaAuthName(authCode) {
      let name = '';
      switch (authCode) {
      case 'AUTHENTICATOR_APP':
        name = 'Authenticator app';
        break;
      case 'SMS_OTP':
        name = 'Sms';
        break;
      default:
        break;
      }
      return name;
    },

    backModalStep() {
      if (!this.verificationLoading) {
        if (this.validationStep === 1) {
          this.backModal();
        } else {
          this.updateValidationStep(this.validationStep - 1);
        }
      }
    },

    getStepLabel(authName) {
      return this.stepsHeader[authName][this.validationStep - 1];
    },

    updateValidationStep(n) {
      this.validationStep = n;
    },

    backModal() {
      this.modalScreen = null;
    },

    closeModal() {
      this.resetForm = false;
      this.validationStep = 1;

      if (
        this.modalScreen
        && this.modalScreen.match(/suggest-enable/)
        && this.suggestEnableAuth
      ) {
        localStorage.setItem('hideSuggestEnableTwoFa', true);
      }

      this.modalScreen = null;
    },

    enableForm(code) {
      this.modalScreen = code;
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    getUserCountryCode() {
      const uc = localStorage.getItem('user_country');

      const tzname = this.$jstz2.determine().name();
      const tzCountry = this.timezones.find(tz => tz.TimeZoneName === tzname);

      if (uc) {
        return uc.split(';')[1];
      }

      if (tzCountry && tzCountry.Country) {
        return tzCountry.Country;
      }

      return '';
    },

    isCountryWhiteListed(userCountry) {
      return this.whiteListedCountries.some(v => v.Country === userCountry);
    },

    async fetchUpdatedData() {
      await this.updateCachedUser();
      this.fetchData();
    },

    fetchData() {
      const compactSidebar = localStorage.getItem('compact-sidebar');
      const isCompactSidebar = parseInt(
        compactSidebar || 0,
        10,
      );
      this.getAuthUser()
        .then(({ data }) => {
          if (!data) {
            throw new Error('No data.');
          }

          this.getUserCacheData({ userId: data.UserId })
            .then(async ({ cacheData }) => {
              const userCacheData = JSON.parse(cacheData);
              const { AccountConfig: accountConfig } = userCacheData || {};
              const twoFaData = await this.updateTwoFA();

              if (accountConfig) {
                const accountSetting = JSON.parse(accountConfig);
                this.enforce2fa = accountSetting.Enforce2FA;
                const isImpersonation = Boolean(
                  localStorage.getItem('cpv3Impersonate'),
                );

                // Check if enforce 2fa config has been enabled and the user has not enabled 2fa
                if (
                  !isImpersonation
                  && this.enforce2fa
                  && twoFaData.filter(d => d.enabled).length <= 0
                ) {
                  this.setEnforce2FA(true);
                  this.show2faSetting = true;
                  this.openSettings('two-factor');
                }
              }
            })
            .catch((err) => {
              this.$showError(this, err);
            });

          // save to local storage
          localStorage.setItem('CPV3_User', JSON.stringify(data));

          // Trigger analytics event
          /* global analytics */
          analytics.identify(data.UserId, {
            Email: data.Email,
            Login: data.Login,
            AccountId: data.AccountId,
          });

          this.userData = data;

          const accountConfig = JSON.parse(data.AccountConfig || '{}');
          this.hideCost = accountConfig?.hide_cost;

          // trigger event to inform dashboard of userChanges
          const event = new Event('userUpdated');
          window.dispatchEvent(event);

          const name = data.Firstname || data.Lastname
            ? `${data.Firstname || ''} ${data.Lastname || ''}`
            : data.Login;

          this.name = name;
          this.needMigrationFromV2 = data.NeedMigrationFromV2;
          this.userId = data.UserId;
          this.accountId = data.AccountId;
          this.login = data.Login;
          this.authUserMangement = data.AccessLevel === 'A'
            || (data.AccessLevel === 'U'
              && data.Roles.some(v => v.match(/UserManagement/i)));
          this.hasPaymentAccess = data.Roles.includes('Payment_v2') && data.IsPostPaid !== 1;
          this.hasSenderIdAccess = data && data.BetaFeatures && data.BetaFeatures.includes('WEB_SenderId-Register_v1');

          this.hasVerif8Enabled = data && data.Product_VERIF8;
          this.onboardVerif8 = data && data.BetaFeatures.includes('WEB_Verif8_V1');

          this.hasAuthSDKAccess = (data && data.Features && data.Features.authSDK) || data.BetaFeatures.includes('WEB_Auth_SDK_V1');

          if (this.hasSenderIdAccess) {
            this.openedAppMenu = 'SMS';
          }

          if (this.isNewWARegistered()) {
            this.getWhatsAppChannels()
              .then((ch = []) => {
                const waChls = ch.filter(v => v.type === 'WhatsApp' && v.status === 'Active');
                if (waChls.length === 1) {
                  return this.getWhatsAppTemplates({ channelId: waChls[0].id });
                }
                return Promise.resolve(undefined);
              }).then((t) => {
                if (Array.isArray(t) && t.length < 1) {
                  this.isNewToWhatsApp = true;
                }

                this.hasDoneWARequest = true;
                this.$nextTick(() => {
                  this.waPopoverVisible = !(parseInt(localStorage.getItem('wa_popover_hidden'), 10) || 0) && !isCompactSidebar && window.location.pathname !== '/chat/register';
                });
              }).catch((err) => {
                this.$showError(this, err);
              });
          } else if (this.showPrepaidCA()) {
            this.hasDoneWARequest = true;

            this.$nextTick(() => {
              this.waPopoverVisible = !(parseInt(localStorage.getItem('wa_popover_hidden'), 10) || 0) && !isCompactSidebar && window.location.pathname !== '/chat/register';
            });
          }

          // get all the common data
          Promise.all([
            this.getUserBalance(data),
            this.getWhitelistedCountries(),
            this.getTimezones(),
          ]).then(async () => {
            const {
              // SiteVersion_MigrationEnabled: migrationEnabled,
              // eslint-disable-next-line no-unused-vars
              SiteVersion_Current: currentVersion,
              PasswordExpiresAt: expiring,
              Username: username,
              SSOEnabled: isSSOEnabled,
            } = data;

            this.userCountryCode = this.getUserCountryCode();
            this.isUserCountryWhiteListed = this.isCountryWhiteListed(this.userCountryCode);

            // Free Credits should be displayed first in first login
            // other modal will follow in the next login

            // Free Credits
            const isShowOtp = Boolean(localStorage.getItem('showFCO'));

            // check if user has freecredits offer
            // check if user is account managed or not
            // check if user's country is in the whitelist
            this.hasFreeCredits = data
              && data.FreeCreditsOffer
              && data.CustomerType !== 'E'
              && this.isUserCountryWhiteListed;

            // this.hasFreeCredits
            // show freecredits otp
            if (this.hasFreeCredits && !isShowOtp) {
              this.showOTP = true;
              this.isModalShow = true;
              localStorage.setItem('showFCO', true);
            }

            // Password expiring
            this.passwordResetSkipped = localStorage.getItem('passwordResetSkipped');


            if (!this.isImpersonation && expiring) {
              // Update UI Flow for Password Expiring

              const expiry = Moment(expiring);
              const now = Moment();
              const d = expiry.diff(now, 'days', true);

              if (d <= 0 && !isSSOEnabled) {
                this.$recaptchaLoaded().then(() => this.$recaptcha('forgot_password'))
                  .then(token => http.v1.post('auth/forgot-password', {
                    email: username,
                    gToken: token,
                    version: 'v3',
                    isPasswordExpired: true,
                  }, {
                    headers: {
                      'api-test': 'invalidate-reCaptcha',
                    },
                  })).then((result) => {
                    const { resetLink } = result.data;
                    http.v1.post('auth/logout').then(() => {
                      this.cleanup();
                      if (resetLink) {
                        window.location = `${resetLink}?feedback=password-expire`;
                      }
                    });
                  })
                  .catch((err) => {
                    this.$showError(this, err, { useMessage: this.$t('errors.invalid'), reloadUrl: '/login/forgot-password' });
                  });
              } else if (d <= 14) {
                this.$message({
                  showClose: false,
                  message: this.$t('warning.password_expiring', { value: Math.round(d, -1) }),
                  type: 'error',
                  duration: 8888,
                });
                this.openSettings('change-password');
              }
            }

            // 2fa suggestion
            // if (!this.disableTwoAuth() && !this.showPasswordExpiring && !this.isModalShow) {
            //   this.checkShowSuggestEnableTwoFa();
            // } -- MAP-1479 hide 2FA
          })
            .catch((err) => {
              // this.$message.error(e.message || 'Failed to load common data.');
              this.$showError(this, err);
            });
        })
        .catch((err) => {
          if (err && err.error === 'Unauthorized') {
            this.cleanup();
            this.$showError(this, err);
          } else {
            this.$showError(this, err);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cleanup() {
      this.isModalShow = false;
      localStorage.removeItem(this.authTokenKey);
      localStorage.removeItem('cpv3Impersonate');
      localStorage.removeItem('CPV3_User');
      localStorage.removeItem('passwordResetSkipped');
      localStorage.removeItem('user_country');
      localStorage.removeItem('verif8-later');
      sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');

      // destroy telemetry service and client
      const { teardownTelemetry } = this.$telemetry;

      teardownTelemetry();
    },

    logout() {
      http.v1
        .post('auth/logout')
        .then(() => {
          // remove userData
          this.cleanup();

          window.location = '/login';
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }
          if (
            err.statusCode === 401
            || (err.error && err.error.match(/Unauthorized/))
          ) {
            this.cleanup();
            // this.showMessage(this.$t('errors.session_expired'), 'error');
            this.$showError(this, err, {
              useMessage: this.$t('errors.session_expired'),
            });
            window.location = '/login';
          } else {
            this.$showError(this, err);
          }
        });
    },

    openSettings(tab) {
      this.isFetching = true;
      let user = {};

      try {
        user = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(user);
      } catch (e) {
        // do nothing
      }
      this.userData = user;
      this.userProfile = Object.assign({}, user);

      this.name = this.userProfile.Firstname || this.userProfile.Lastname
        ? `${this.userProfile.Firstname || ''} ${this.userProfile.Lastname || ''
        }`
        : this.userProfile.Login;
      this.isAdmin = this.userProfile.AccessLevel === 'A';

      this.isFetching = false;
      let tabStr = tab;
      if (!tab) {
        tabStr = 'account';
      }

      this.showProfile(tabStr);
    },

    openUserManagement() {
      this.$router.push({ path: '/user-management' });
    },

    openMultichannel() {
      this.$router.push({ path: '/multichannel-sender' });
    },

    handleCommand(command) {
      switch (command) {
      case 'logout':
        this.logout();
        break;
      case 'settings':
        this.openSettings();
        break;
      case 'usermanagement':
        this.openUserManagement();
        break;
      case 'multichannelsender':
        this.openMultichannel();
        break;
      default:
        break;
      }
    },

    showProfile(tab) {
      let t = tab;
      if (tab === 'account' && !this.isAdmin) {
        t = 'profile';
      }

      this.tab = t;
      this.showProfileDialog = true;
    },

    closePasswordExpiring() {
      localStorage.setItem('passwordResetSkipped', true);
      this.showPasswordExpiring = false;
    },

    closeOTPModal() {
      this.showOTP = false;
    },

    showOTPModal() {
      this.showOTP = true;
    },

    updateUserAndBalance() {
      // update user
      this.getAuthUser()
        .then(({ data }) => {
          if (!data) {
            throw new Error('No data.');
          }

          this.userData = { ...data };
          this.userProfile = { ...data };

          this.hasFreeCredits = data
            && data.FreeCreditsOffer
            && data.CustomerType !== 'E'
            && this.isUserCountryWhiteListed;

          // update local storage
          localStorage.setItem('CPV3_User', JSON.stringify(data));

          this.getUserBalance(this.userData);
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    showBalanceAlert() {
      const {
        CustomerType,
        IsTrialAccount,
        IsPostPaid,
        ShowBalanceAlert,
        LowBalanceThreshold,
        LowBalanceAlertEnabled,
      } = this.userData;

      if (
        this.balance
        && !!LowBalanceAlertEnabled
        && !_.isNull(LowBalanceThreshold)
        && !!ShowBalanceAlert
        && !IsTrialAccount
        && !IsPostPaid
        && CustomerType === 'E'
      ) {
        return true;
      }

      return false;
    },

    isNewWARegistered() {
      const {
        BetaFeatures,
        // eslint-disable-next-line camelcase
        Product_CA: productCA,
        Roles,
        AccessLevel,
      } = this.userData;

      const userRoles = Roles || [];

      const whatsAppRoles = ['CA.Channels_v2', 'CA.WhatsAppTemplates_v2'].every(v => userRoles.includes(v));

      // Included in beta feature but user hasn't activated it
      // so show coachmark
      return BetaFeatures && BetaFeatures.includes('CA-Prepaid') && productCA && (whatsAppRoles || AccessLevel === 'A');
    },
    handleWhatsNew() {
      if (this.showPrepaidCA() || (this.isNewWARegistered() && this.isNewToWhatsApp)) {
        this.waPopoverVisible = true;
      }
    },

    showPrepaidCA() {
      const {
        BetaFeatures,
        // eslint-disable-next-line camelcase
        Product_CA: productCA,
      } = this.userData;

      // Included in beta feature but user hasn't activated it
      // so show coachmark
      return BetaFeatures && BetaFeatures.includes('CA-Prepaid') && !productCA;
    },

    hasAutomation() {
      const { Product_AT: hasAutomation } = this.userData;

      return Boolean(hasAutomation);
    },

    // eslint-disable-next-line consistent-return
    beforeDialogClose(done) {
      // Check if enforce 2fa config has been enabled and the user has not enabled 2fa
      // Disallow closing of the modal
      if (
        this.show2faSetting
        && this.enforce2fa
        && this.getTwoFA.filter(d => d.enabled).length <= 0
      ) {
        this.$message.warning('Your admin has enforced you to setup 2FA');
        return false;
      }
      done();
    },

    // eslint-disable-next-line no-unused-vars
    closeWAPopover(isNewRegistered = false) {
      this.waPopoverVisible = false;


      const isNewFeaturesDialogHidden = Number(localStorage.getItem('wa_dialog_hidden'));

      if (!isNewFeaturesDialogHidden) {
        this.waDialogVisible = true;
      } else {
        localStorage.setItem('wa_popover_hidden', 1);
      }
    },

    openWAPopover() {
      this.waPopoverVisible = true;
    },

    closeWAInfoDialog({ checked }) {
      this.waDialogVisible = false;

      if (checked) {
        localStorage.setItem('wa_dialog_hidden', 1);
      }
    },

    trackRedirects(routeRef) {
      const activeRoute = this.activeRoute || window.location.pathname;
      const activePath = activeRoute.replace(/^\/|\/$/g, '');
      switch (routeRef) {
      case 'messaging-sender-id-documents-details':
        if (!activePath) {
          const { track } = this.$telemetry;
          track('documents_details_initiated', {
            uiArea: 'sender_id_management_page',
            userId: this.userId,
            accountId: this.accountId,
          });
        }
        break;
      default:
      }
    },
  },
};
