<template>
  <el-form
    ref="profileForm"
    v-loading="isFetching"
    :rules="profileRules"
    label-position="top"
    :model="profile"
    @submit.native.prevent="updateUser"
  >
    <h4 class="mt-2">
      {{ $t('user.personal_details') }}
    </h4>
    <hr
      class="border border-solid border-t border-b-0"
    >
    <div class="row profile-form flex flex-wrap">
      <div class="w-1/2 pr-1">
        <el-form-item
          :label="$t('fields.first_name')"
          prop="firstName"
        >
          <el-input
            v-model="profile.firstName"
            size="small"
            :placeholder="$t('fields.first_name')"
          />
        </el-form-item>
      </div>
      <div class="w-1/2 pr-1">
        <el-form-item
          :label="$t('fields.last_name')"
          prop="lastName"
        >
          <el-input
            v-model="profile.lastName"
            :placeholder="$t('fields.last_name')"
            size="small"
          />
        </el-form-item>
      </div>
      <div class="w-full">
        <el-form-item :label="$t('fields.email')">
          <span class="sub-label">
            {{ $t('user.change_current_email') }}
            <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
          </span>
          <el-input
            :disabled="(true)"
            :value="profile.email"
            class="mb-0"
            size="small"
          />
        </el-form-item>
        <el-checkbox
          v-model="profile.optIn"
          class="pt-0 mt-0 pb-3"
        >
          <span class="text-grey font-light">
            {{ $t('user.marketing_email') }}
          </span>
        </el-checkbox>
      </div>
      <div class="w-full">
        <el-form-item
          :label="$t('fields.phone_number')"
          prop="phone"
        >
          <el-input
            v-model="profile.phone"
            :placeholder="$t('fields.phone_number')"
            :disabled="updatePhoneDisabled"
            size="small"
          />
        </el-form-item>
      </div>
      <!-- <div class="w-full">
        <el-form-item :label="$t('fields.timezone')">
          <el-select
            v-model="profile.timezoneId"
            filterable
            :placeholder="$t('fields.timezone')"
            class="w-full"
            size="small"
          >
            <el-option
              v-for="tz in timezones"
              :key="tz.TimeZoneId"
              :label="tz.Combined"
              :value="tz.TimeZoneId"
            />
          </el-select>
        </el-form-item>
        <span
          v-if="userProfile.TimeZoneId !== profile.timezoneId"
          class="text-red mb-4"
        >
          {{ $t('user.timezone_reminder') }}
        </span>
      </div> -->
      <div class="w-1/2 flex-grow">
        &nbsp;
      </div>
      <div class="w-1/2 flex-1 content-right">
        <el-form-item>
          <el-button
            type="primary"
            native-type="submit"
            size="small"
            class="w-full"
            @click.native.prevent="updateUser"
          >
            {{ $t('actions.update') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  events: {
    reset() {
      this.$refs.passwordForm.resetFields();
    },
  },

  props: {
    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const self = this;

    return {
      isFetching: true,
      profile: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        timezoneId: null,
        optIn: false,
      },
      toppedUp: false,
      isEnterprise: false,
      profileRules: {
        firstName: [
          {
            type: 'string',
            message: self.$t('validations.char_limit', { value: '100' }),
            max: 100,
            trigger: 'blur',
          },
          {
            type: 'string',
            message: self.$t('validations.valid_char'),
            pattern: /^[a-zA-Z0-9 \-_.]+$/,
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            type: 'string',
            message: self.$t('validations.char_limit', { value: '100' }),
            max: 100,
            trigger: 'blur',
          },
          {
            type: 'string',
            message: self.$t('validations.valid_char'),
            pattern: /^[a-zA-Z0-9 \-_.]+$/,
            trigger: 'blur',
          },
        ],
        phone: [
          {
            type: 'string',
            pattern: /^\+?[1-9]\d{4,14}$/,
            message: self.$t('validations.valid_char'),
            trigger: 'change',
            transform(value) {
              if (!value) return value;

              return value.trim();
            },
          },
        ],
      },
      supportEmail: this.appConfig.supportEmail,
    };
  },

  computed: {
    ...mapState('account', ['userProfile']),

    ...mapState('common', [
      'timezones',
    ]),

    updatePhoneDisabled() {
      if (this.isEnterprise) {
        return false;
      }

      if (this.toppedUp) {
        return false;
      }

      return true;
    },
  },

  watch: {
    resetForm: {
      handler(val) {
        if (val) {
          this.resetFormContent();
        }
      },
    },

    userProfile: {
      handler() {
        const name = this.userProfile.Firstname || this.userProfile.Lastname
          ? `${this.userProfile.Firstname || ''} ${this.userProfile.Lastname || ''}` : this.userProfile.Login;

        this.$emit('setName', name);
      },
    },
  },

  created() {
    this.fetchTimezones();
    this.fetchProfile();
  },

  methods: {
    ...mapActions({
      updateUserProfile: 'account/updateUserProfile',
      getUserProfile: 'account/getUserProfile',
      getTransactions: 'account/getTransactions',
      getTimezones: 'common/getTimezones',
      setTimezones: 'common/setTimezones',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    async fetchProfile() {
      const uid = this.userData.UserId;
      this.getUserProfile({
        uid,
      })
        .then(() => {
          const data = this.userProfile;
          this.isEnterprise = (data.CustomerType === 'E' || data.CustomerType === 'W');
          this.profile = this.normalizeUserData(data);
          this.isFetching = false;
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });

      // call only if user has payment access
      if (this.userData && Object.keys(this.userData).length && this.userData.Roles.includes('Payment_v2') && this.userData.IsPostPaid !== 1) {
        try {
          const transactions = await this.getTransactions();
          if (Array.isArray(transactions) && transactions.length) {
            const successTrans = transactions.filter(t => t.TrxIntStatus.toUpperCase() === 'SUCCESS').length;
            this.toppedUp = successTrans > 0;
          }
        } catch (err) {
          this.$showError(this, err);
        }
      }
    },

    normalizeUserData(data) {
      return {
        firstName: data.Firstname,
        lastName: data.Lastname,
        email: data.Login,
        phone: data.Phone,
        timezoneId: data.TimeZoneId,
        optIn: data.OptIn_Marketing || false,
      };
    },

    setProfileTimezone() {
      if (!this.profile.timezoneId) {
        const tzname = this.$jstz2.determine().name();

        const timezone = this.timezones.find(tz => tz.TimeZoneName === tzname);

        this.profile.timezoneId = (timezone) ? timezone.TimeZoneId : null;
      }
    },

    getTimezonesList() {
      this.getTimezones()
        .then((tzs) => {
          window.localStorage.setItem('timezones', JSON.stringify(tzs));
          this.setProfileTimezone();
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    fetchTimezones() {
      try {
        // Check if we can fetch timezones from localStorage first
        const timezones = JSON.parse(window.localStorage.getItem('timezones') || []);

        if (Array.isArray(timezones) && timezones.length > 0) {
          this.setTimezones(timezones);
          this.setProfileTimezone();
        } else {
          this.getTimezonesList();
        }
      } catch (e) {
        this.getTimezonesList();
      }
    },

    updateUser() {
      this.$refs.profileForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.isFetching = true;
        const uid = this.userData.UserId;

        const payload = { ...this.profile };

        delete payload.id;
        delete payload.email;

        payload.timezoneId = parseInt(payload.timezoneId, 10);

        this.updateUserProfile({
          uid,
          data: payload,
        })
          .then((res) => {
            this.showMessage(this.$t('success.updated', { value: this.$t('fields.profile') }), res.status);
          })
          .then(() => this.fetchProfile())
          .then(() => {
            try {
              const cpv3User = localStorage.getItem('CPV3_User') || {};
              const user = JSON.parse(cpv3User);

              user.Firstname = this.profile.firstName;
              user.Lastname = this.profile.lastName;

              // Encrypt data so that its not readable by ordinary users
              localStorage.setItem('CPV3_User', JSON.stringify(user));

              const event = new Event('userUpdated');
              window.dispatchEvent(event);
            } catch (e) {
              // do nothing
            }

          // //   this.isFetching = false;
          })
          .catch((err) => {
            this.isFetching = false;
            this.$showError(this, err);
            // this.showMessage(response.body.message, 'error');
          });
      });
    },

    resetFormContent() {
      this.fetchProfile();
      this.fetchTimezones();
    },
  },
};

</script>
<style>
.profile-form .el-form-item__label {
  padding-bottom: 0px;
}
</style>
