//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CodeInput from './CodeInput.vue';

export default {
  name: 'AuthGoogle',

  components: {
    CodeInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    generateQr: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      qrCode: '',
      qrLoading: false,
      otpCode: '',
    };
  },

  created() {
    this.generateQrCode();
  },

  methods: {
    generateQrCode() {
      this.qrLoading = true;
      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_2fa_otp'))
        .then(token => this.generateQr({ gToken: token }))
        .then((res) => {
          this.qrCode = res;
        })
        .catch((err) => {
          this.qrCode = '';
          this.$showError(this, err);
        })
        .finally(() => {
          this.qrLoading = false;
        });
    },

    submit() {
      if (this.otpCode) {
        const payload = {
          type: 'AUTHENTICATOR_APP',
          token: this.otpCode,
        };
        this.$emit('submit', payload);
      }
    },

    cancel() {
      this.$emit('back');
    },
  },
};
