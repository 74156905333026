<template>
  <el-form
    ref="accountForm"
    v-loading="isFetching"
    :rules="accountRules"
    label-position="top"
    :model="account"
    @submit.native.prevent="updateAccount"
  >
    <h4 class="mt-2">
      {{ $t('user.account_details') }}
    </h4>
    <hr
      class="border border-solid border-t"
    >
    <div class="row profile-form flex flex-wrap">
      <div class="w-full">
        <el-form-item
          :label="$t('fields.account_name')"
          prop="accountName"
        >
          <small>
            <i class="el-icon-info text-blue" />
            <span class="text-muted">
              {{ $t('user.account_details') }}
            </span>
          </small>
          <el-input
            v-model="account.accountName"
            :placeholder="$t('fields.account_name')"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-full">
        <el-form-item
          :label="$t('fields.subaccounts')"
        >
          <div class="sb-box">
            <el-tag
              v-for="s in subAccounts"
              :key="s.SubAccountId"
              class="mb-1 mr-1"
              type="info"
            >
              {{ s.SubAccountId }}
            </el-tag>
          </div>
        </el-form-item>
      </div>

      <div class="w-full">
        <div class="flex items-start">
          <h4 class="mt-3">
            {{ $t('fields.data_center_region') }}
          </h4>
          <el-popover
            ref="popup"
            placement="top-start"
            width="300"
            trigger="hover"
          >
            {{ $t('user.date_center_region[0]') }} <a
              href="https://connect.8x8.com/support/tickets/create"
              target="_blank"
            >{{ $t('user.date_center_region[1]') }}</a> {{ $t('user.date_center_region[2]') }}
          </el-popover>
          <i
            v-popover:popup
            class="el-icon-question text-grey-darker"
          />
        </div>
        <hr class="border border-solid border-t border-b-0">
      </div>

      <div class="w-full mb-6">
        <h4 class="mt-3 mb-1">
          {{ dataCenterRegion }}
        </h4>
      </div>

      <div class="w-full">
        <h4 class="mt-3">
          {{ $t('user.billing_details') }}
        </h4>
        <hr class="border border-solid border-t border-b-0">
      </div>

      <div class="w-1/2 pr-1">
        <h4 class="mt-3 mb-1">
          {{ $t('fields.billing_currency') }}
        </h4>
        <p>{{ currencyName }} ({{ currency }})</p>
      </div>

      <div class="w-1/2 pl-1">
        <el-form-item
          :label="$t('fields.invoice_emails')"
          prop="invoiceEmails"
        >
          <el-select
            v-model="account.invoiceEmails"
            multiple
            filterable
            allow-create
            remote
            default-first-option
            class="w-full"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/2 pr-1">
        <el-form-item
          :label="$t('fields.company_name')"
          prop="companyName"
        >
          <el-input
            v-model="account.companyName"
            :placeholder="$t('fields.company_name')"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/2 pl-1">
        <el-form-item
          :label="$t('fields.country')"
          prop="country"
        >
          <el-select
            v-model="account.country"
            filterable
            :placeholder="$t('fields.country')"
            class="w-full"
            disabled="{!!account.country}"
            size="small"
          >
            <el-option
              v-for="country in countries"
              :key="country.CountryISO2alpha"
              :label="country.CountryName"
              :value="country.CountryISO2alpha"
            >
              <span :class="flagify(country.CountryISO2alpha)" />
              <span class="text-muted">
                {{ country.CountryName }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="w-1/2 pr-1">
        <el-form-item
          :label="$t('fields.address_line', { no: '1'})"
          prop="addressLine1"
        >
          <el-input
            v-model="account.addressLine1"
            :placeholder="$t('fields.address_line', { no: '1'})"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/2 pl-1">
        <el-form-item
          :label="$t('fields.address_line', { no: '2'})"
          prop="addressLine2"
        >
          <el-input
            v-model="account.addressLine2"
            :placeholder="$t('fields.address_line', { no: '2'})"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/3 pr-1">
        <el-form-item
          :label="$t('fields.city')"
          prop="addressCity"
        >
          <el-input
            v-model="account.addressCity"
            :placeholder="$t('fields.city')"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/3 pr-1 pl-1">
        <el-form-item
          :label="$t('fields.state')"
          prop="addressState"
        >
          <el-input
            v-model="account.addressState"
            :placeholder="$t('fields.state')"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/3 pl-1">
        <el-form-item
          :label="$t('fields.zip')"
          prop="addressZip"
        >
          <el-input
            v-model="account.addressZip"
            :placeholder="$t('fields.zip')"
            size="small"
          />
        </el-form-item>
      </div>

      <div class="w-1/2 flex-grow">
        &nbsp;
      </div>
      <div class="w-1/2 flex-1 content-right">
        <el-form-item>
          <el-button
            type="primary"
            native-type="submit"
            class="w-full"
            size="small"
            @click.native.prevent="updateAccount"
          >
            {{ $t('actions.update') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  events: {
    reset() {
      this.$refs.passwordForm.resetFields();
    },
  },

  props: {
    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const self = this;

    let dataCenterRegion = '';
    const user = JSON.parse(localStorage.getItem('CPV3_User'));
    const regionId = (user && user.RegionId) ?? 0;

    switch (regionId) {
    case 1:
      dataCenterRegion = 'Indonesia';
      break;
    case 2:
      dataCenterRegion = 'Europe';
      break;
    case 3:
      dataCenterRegion = 'North America';
      break;
    default:
      dataCenterRegion = 'Asia Pacific';
    }

    return {
      dataCenterRegion,
      isFetching: true,
      countries: [],
      currencyName: 'Euro',
      currency: 'EUR',
      account: {
        accountName: '',
        companyName: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZip: '',
        invoiceEmails: [],
      },
      accountRules: {
        accountName: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.account_name') }),
            trigger: 'blur',
            transform(value = '') {
              return value.trim();
            },
          },
          {
            pattern: /^[a-zA-Z0-9]*$/,
            message: self.$t('validations.alphanumeric', { value: self.$t('fields.account_name') }),
            trigger: 'change',
          },
          {
            min: 6,
            max: 255,
            message: self.$t('validations.between_char_limit', { value: '6 to 255' }),
            trigger: 'change',
          },
        ],
        country: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.select', { value: self.$t('fields.billing_country') }),
            trigger: 'blur',
          },
        ],
        addressLine1: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.address_line', { no: '1' }) }),
            trigger: 'blur',
            transform(value = '') {
              return value.trim();
            },
          },
          {
            max: 200,
            min: 1,
            message: self.$t('validations.valid', { value: self.$t('fields.address_line', { no: '1' }) }),
            trigger: 'blur',
          },
        ],
        addressCity: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.city') }),
            trigger: 'blur',
            transform(value = '') {
              return value.trim();
            },
          },
          {
            max: 50,
            min: 1,
            message: self.$t('validations.valid', { value: self.$t('fields.city') }),
            trigger: 'blur',
          },
        ],
        addressState: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.state') }),
            trigger: 'blur',
            transform(value = '') {
              return value.trim();
            },
          },
          {
            max: 50,
            min: 1,
            message: self.$t('validations.valid', { value: self.$t('fields.state') }),
            trigger: 'blur',
          },
        ],
        addressZip: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.zip') }),
            trigger: 'blur',
            transform(value = '') {
              return value.trim();
            },
          },
          {
            max: 50,
            min: 1,
            message: self.$t('validations.valid', { value: self.$t('fields.zip') }),
            trigger: 'blur',
          },
        ],
        invoiceEmails: [
          {
            validator: (rules, val, cb) => {
              if (val.length === 0) {
                cb();
                return;
              }

              // Check if every item is a valid email address, otherwise
              // show an invalid message
              val.forEach((v) => {
                  if (!v.match(this.$constants.EMAIL_REGEX)) { // eslint-disable-line
                  cb(new Error(self.$t('validations.valid', { value: self.$t('fields.email') })));
                }
              });
              // Valid
              cb();
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    ...mapState('account', ['userAccount']),
    ...mapState('common', ['subAccounts']),

    flagify() {
      return (val) => {
        const country = val.toLowerCase();

        return `flag-icon flag-icon-${country} flag-icon-squared flag`;
      };
    },
  },

  // watch: {
  //   resetForm: {
  //     handler(val) {
  //       if (val) {
  //         this.resetFormContent();
  //       }
  //     },
  //   },
  // },

  created() {
    this.fetchCountries();
    this.fetchAccount();
    this.fetchSubAccounts();
  },

  methods: {
    ...mapActions({
      updateAct: 'account/updateUserAccount',
      getAct: 'account/getUserAccount',
      getCountries: 'common/getCountries',
      getSubAccounts: 'common/getSubAccounts',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    fetchSubAccounts() {
      const user = { AccountUid: this.userData.AccountUid };

      this.getSubAccounts(user)
        .then(() => {})
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    getCountriesList() {
      this.getCountries()
        .then((response) => {
          const data = response;
          this.countries = data;
          window.localStorage.setItem('countries', JSON.stringify(this.countries));
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    fetchCountries() {
      try {
        // Fetch countries from localStorage first
        const countries = JSON.parse(window.localStorage.getItem('countries') || []);
        if (Array.isArray(countries) && countries.length > 0) {
          this.countries = countries;
          return;
        }

        this.getCountriesList();
      } catch (e) {
        this.getCountriesList();
      }
    },

    normalizeAccountData(data) {
      return {
        accountName: data.AccountName,
        companyName: data.CompanyName,
        country: data.Country,
        addressLine1: data.Address_Line1,
        addressLine2: data.Address_Line2,
        addressCity: data.Address_City,
        addressState: data.Address_State,
        addressZip: data.Address_Zip,
        invoiceEmails: (() => {
          if (Array.isArray(data.InvoiceEmails)) {
            return data.InvoiceEmails;
          }

          return data.InvoiceEmails ? data.InvoiceEmails.split(',')
              .filter(email => email.match(this.$constants.EMAIL_REGEX)) : []; // eslint-disable-line
        })(),
      };
    },

    fetchAccount() {
      const uid = this.userData.UserId;
      const aid = this.userData.AccountId;

      this.currencyName = this.userData.CurrencyName ? this.userData.CurrencyName : 'Euro';
      this.currency = this.userData.Currency ? this.userData.Currency : 'EUR';

      this.getAct({
        uid,
        aid,
      })
        .then(() => {
          const data = this.userAccount;
          this.account = this.normalizeAccountData(data);
          this.isFetching = false;
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    updateAccount() {
      this.$refs.accountForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.isFetching = true;
        const uid = this.userData.UserId;
        const aid = this.userData.AccountId;

        this.updateAct({
          uid,
          aid,
          data: this.account,
        })
          .then(() => {
            this.showMessage(this.$t('success.updated', { value: this.$t('fields.account') }), 'success');

            return this.getAct({
              uid,
              aid,
            });
          })
          .then(() => {
            // do nothing
          })
          .catch((err) => {
            this.$showError(this, err);
            // this.showMessage(response.body.message, 'error');
          })
          .then(() => {
            this.isFetching = false;
          });
      });
    },

    resetFormContent() {
      this.fetchAccount();
    },
  },
};

</script>
