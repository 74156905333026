//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { getExample, parsePhoneNumber } from 'awesome-phonenumber';
import countriesJson from '../../../src/json/countries.json';


export default {
  events: {
    reset() {
      this.$refs.passwordForm.resetFields();
    },
  },

  props: {
    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const self = this;

    return {
      isFetching: true,
      profile: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        timezoneId: null,
        optIn: false,
      },
      toppedUp: false,
      isEnterprise: false,
      countries: [],
      selectedCallingCode: '',
      contactPlaceholder: '',
    };
  },

  computed: {
    ...mapState('account', ['userProfile']),

    ...mapState('common', [
      'timezones',
    ]),
  },

  watch: {
    resetForm: {
      handler(val) {
        if (val) {
          this.resetFormContent();
        }
      },
    },

    userProfile: {
      handler() {
        const name = this.userProfile.Firstname || this.userProfile.Lastname
          ? `${this.userProfile.Firstname || ''} ${this.userProfile.Lastname || ''}` : this.userProfile.Login;

        this.$emit('setName', name);
      },
    },
  },

  created() {
    this.fetchTimezones();
    this.fetchProfile();

    this.countries = countriesJson;

    const userCountry = this.userData.CountryCode;
    const country = this.countries.find(c => c.value === userCountry);


    if (userCountry) {
      this.selectedCallingCode = `${country.value}-${country.name}_${country.callingCode}`;
    } else {
      const defaultCountry = localStorage.getItem('user_country');
      const defaultcountryCode = defaultCountry ? defaultCountry.split(';')[1] : '';
      const dCountry = this.countries.find(c => c.value === defaultcountryCode);
      this.selectedCallingCode = `${dCountry.value}-${dCountry.name}_${dCountry.callingCode}`;
    }
  },

  methods: {
    ...mapActions({
      updateUserProfile: 'account/updateUserProfile',
      getUserProfile: 'account/getUserProfile',
      getTransactions: 'account/getTransactions',
      getTimezones: 'common/getTimezones',
      setTimezones: 'common/setTimezones',
    }),

    getCountryCode() {
      return this.selectedCallingCode.substring(0, this.selectedCallingCode.indexOf('-'));
    },
    getCallingCode() {
      return this.selectedCallingCode.split('_')[1];
    },

    getCountryName() {
      const countryCode = this.getCountryCode();
      return this.countries.find(c => c.value === countryCode).name;
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    async fetchProfile() {
      const uid = this.userData.UserId;
      this.getUserProfile({
        uid,
      })
        .then(() => {
          const data = this.userProfile;
          this.isEnterprise = (data.CustomerType === 'E' || data.CustomerType === 'W');
          this.profile = this.normalizeUserData(data);
          this.isFetching = false;
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });

      // call only if user has payment access
      if (this.userData && Object.keys(this.userData).length && this.userData.Roles.includes('Payment_v2') && this.userData.IsPostPaid !== 1) {
        try {
          const transactions = await this.getTransactions();
          if (Array.isArray(transactions) && transactions.length) {
            const successTrans = transactions.filter(t => t.TrxIntStatus.toUpperCase() === 'SUCCESS').length;
            this.toppedUp = successTrans > 0;
          }
        } catch (err) {
          this.$showError(this, err);
        }
      }
    },

    normalizeUserData(data) {
      return {
        firstName: data.Firstname,
        lastName: data.Lastname,
        email: data.Login,
        phone: data.Phone,
        timezoneId: data.TimeZoneId,
        optIn: data.OptIn_Marketing || false,
        countryCode: data.CountryCode,
      };
    },

    setProfileTimezone() {
      if (!this.profile.timezoneId) {
        const tzname = this.$jstz2.determine().name();

        const timezone = this.timezones.find(tz => tz.TimeZoneName === tzname);

        this.profile.timezoneId = (timezone) ? timezone.TimeZoneId : null;
      }
    },

    getTimezonesList() {
      this.getTimezones()
        .then((tzs) => {
          window.localStorage.setItem('timezones', JSON.stringify(tzs));
          this.setProfileTimezone();
        })
        .catch((err) => {
          this.$showError(this, err);
          // this.showMessage(err.message, 'error');
        });
    },

    fetchTimezones() {
      try {
        // Check if we can fetch timezones from localStorage first
        const timezones = JSON.parse(window.localStorage.getItem('timezones') || []);

        if (Array.isArray(timezones) && timezones.length > 0) {
          this.setTimezones(timezones);
          this.setProfileTimezone();
        } else {
          this.getTimezonesList();
        }
      } catch (e) {
        this.getTimezonesList();
      }
    },

    updateUser() {
      this.$refs.preferenceForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.isFetching = true;
        const uid = this.userData.UserId;

        const payload = {
          ...this.profile,
          countryCode: this.getCountryCode(),
        };

        delete payload.id;
        delete payload.email;

        payload.timezoneId = parseInt(payload.timezoneId, 10);

        this.updateUserProfile({
          uid,
          data: payload,
        })
          .then((res) => {
            this.showMessage(this.$t('success.updated', { value: this.$t('fields.profile') }), res.status);
          })
          .then(() => this.fetchProfile())
          .then(() => {
            try {
              const cpv3User = localStorage.getItem('CPV3_User') || {};
              const user = JSON.parse(cpv3User);

              user.Firstname = this.profile.firstName;
              user.Lastname = this.profile.lastName;
              user.CountryCode = this.profile.countryCode;

              // Encrypt data so that its not readable by ordinary users
              localStorage.setItem('CPV3_User', JSON.stringify(user));

              const event = new Event('userUpdated');
              window.dispatchEvent(event);
            } catch (e) {
              // do nothing
            }

          // //   this.isFetching = false;
          })
          .catch((err) => {
            this.isFetching = false;
            this.$showError(this, err);
            // this.showMessage(response.body.message, 'error');
          });
      });
    },

    resetFormContent() {
      this.fetchProfile();
      this.fetchTimezones();
    },
  },
};

