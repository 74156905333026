//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import step1Icon from '../../assets/two-fa-step1.svg';
import step2Icon from '../../assets/two-fa-step2.svg';
import step3Icon from '../../assets/two-fa-step3.svg';

export default {
  name: 'AuthHelp',

  data() {
    return {
      step1Icon,
      step2Icon,
      step3Icon,
    };
  },

  methods: {
    setupTwoFa() {
      this.$emit('setup-two-fa');
    },
    close() {
      this.$emit('close');
    },
  },
};
