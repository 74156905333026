/* eslint-disable prefer-promise-reject-errors */
import Vue from 'vue';
import { Promise } from 'es6-promise';
import http from '../../../../utils/http';

export default {
  async updateTwoFA({ commit }) {
    try {
      const { data } = await http.v2.get('2fa');
      commit('SET_TWO_FA', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async validatePassword({ commit }, p) {
    try {
      const { id, ...payload } = p;
      const { data } = await http.v1.post(`users/${id}/verify-password`, {
        ...payload,
      });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async removeAllTwoFa({ commit }, payload) {
    try {
      const { data } = await http.v2.delete('2fa/all', {
        ...payload,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async removeTwoFa({ commit }, payload) {
    try {
      const { data } = await http.v2.delete(`2fa/${payload.type}`, { ...payload }); // eslint-disable-line
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async setDefaultTwoFa({ commit }, payload) {
    try {
      const { data } = await http.v2.put(`2fa/${payload.type}`);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async enableTwoFa({ commit }, payload) {
    try {
      const { data } = await http.v2.post('2fa/verify', {
        ...payload,
      });
      if (data.verified) {
        return data;
      }
      return Promise.reject('Invalid code');
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getTwoFaQrCode({ commit }, p) {
    try {
      const payload = { type: 'AUTHENTICATOR_APP', ...p };
      const { data } = await http.v2.post('2fa', {
        ...payload,
      });
      return data.qrUrl;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getTwoFaOtpCode({ commit }, p) {
    try {
      const payload = { type: 'SMS_OTP', ...p };
      const { data } = await http.v2.post('2fa', {
        ...payload,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updateUserAccount({ commit }, payload) {
    try {
      const { data } = await http.v1.post(`accounts/${payload.uid}/${payload.aid}`, {
        ...payload.data,
      });
      commit('UPDATED_ACCOUNT', true);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getUserAccount({ commit }, payload) {
    try {
      const { data } = await http.v1.get(`accounts/${payload.uid}/${payload.aid}`);
      commit('SET_USER_ACCOUNT', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updateUserProfile({ commit }, payload) {
    try {
      const { data } = await http.v1.post('users/update-user-profile', {
        ...payload.data,
      });
      commit('UPDATED_PROFILE', true);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getUserProfile({ commit }) {
    try {
      const { data } = await http.v1.get('users/profile');
      Vue.prototype.$userData = data;
      commit('SET_USER_PROFILE', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getTransactions({ commit }) {
    try {
      const { data } = await http.v1.get('payments/transactions');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updatePassword({ commit }, payload) {
    try {
      const { data } = await http.v1.post('users/change-password', {
        ...payload.data,
      });
      localStorage.setItem('WWW-Authenticate', data.auth_token);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getBalanceAlertSettings({ commit }, payload) {
    try {
      const { data } = await http.v1.get(`accounts/${payload.accountUid}/alert-settings`);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getAdminEmails({ commit }, payload) {
    try {
      const { data } = await http.v1.get(`accounts/${payload.accountUid}/admin-emails`);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async updateBalanceAlertSettings({ commit }, payload) {
    try {
      const { data } = await http.v1.put(`accounts/${payload.accountUid}/alert-settings`, {
        ...payload.data,
      });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async enableSMS2FA({ commit }, payload) {
    try {
      const { data } = await http.v2.post('auth_sdk/sms-otp/verify', {
        ...payload,
      });
      if (data.success) {
        return data;
      }
      return data || {};
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
