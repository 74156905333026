//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CodeInput',

  props: {
    value: {
      type: String,
      required: true,
    },

    length: {
      type: Number,
      default: 6,
    },

    pattern: {
      type: String,
      default: /^[a-zA-Z0-9]$/,
    },

    placeholder: {
      type: String,
      default: '—',
    },
  },

  data() {
    return {
      codeArray: [],
    };
  },

  created() {
    this.codeArray = new Array(this.length).fill(-1);
  },

  methods: {
    updateValue(e, index) {
      const val = e.key;
      if (val.match(this.pattern)) {
        this.codeArray = this.codeArray.map((v, i) => (i === index - 1 ? val : v));
        const code = !this.codeArray.includes(-1)
          ? this.codeArray.join('')
          : null;
        this.$emit('input', code);

        // Next input focus
        if (index < this.length) {
          this.$refs[`otp-code${index + 1}`][0].focus();
        }
      } else {
        e.preventDefault();
      }
    },

    removeValue(e, index) {
      if (e.keyCode === 8 || e.keyCode === 46) {
        this.codeArray = this.codeArray.map((v, i) => (i === index - 1 ? -1 : v));
        this.$emit('input', '');

        // Prev input focus
        if (index > 1) {
          this.$refs[`otp-code${index - 1}`][0].focus();
        }
      }
    },

    getDisplayValue(i) {
      const val = this.codeArray[i - 1];
      return val !== -1 ? val : '';
    },

    // Remove highlight input text
    selectInput() {
      window.getSelection().removeAllRanges();
    },
  },
};
