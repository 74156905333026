import actions from './actions';

export default {
  namespaced: true,

  state: {
    userAccount: {},
    userProfile: {},
    isPasswordChanged: false,
    isAccountUpdated: false,
    isProfileUpdated: {},
    twoFA: [],
    enforce2FA: false,
  },

  getters: {
    getTwoFA: st => st.twoFA,
    getEnforce2FA: st => st.enforce2FA,
  },

  mutations: {
    SET_TWO_FA(state, payload) {
      state.twoFA = payload; // eslint-disable-line
    },

    SET_USER_ACCOUNT(state, payload) {
      state.userAccount = payload; // eslint-disable-line
    },

    UPDATED_ACCOUNT(state, payload) {
      state.isAccountUpdated = payload; // eslint-disable-line
    },

    CHANGE_PASSWORD(state, payload) {
      state.isPasswordChanged = payload; // eslint-disable-line
    },

    UPDATED_PROFILE(state, payload) {
      state.isProfileUpdated = payload; // eslint-disable-line
    },

    SET_USER_PROFILE(state, payload) {
      state.userProfile = payload; // eslint-disable-line
    },

    SET_ENFORCE_2FA(state, payload) {
      state.enforce2FA = payload; // eslint-disable-line
    },
  },

  actions,
};
