<template>
  <div
    class="card border border-solid bg-white p-8 relative h-full"
    :class="[{'card--recommend': !enabled && recommend}]"
  >
    <span
      v-if="!enabled && recommend"
      class="card__tag card__tag--recommend tracking-wide"
    >
      {{ $t('user.auth_card[0]') }}
    </span>
    <span
      v-else-if="enabled"
      class="card__tag card__tag--enabled tracking-wide"
    >
      {{ $t('user.auth_card[1]') }}
    </span>

    <div
      class="card__image p-2 text-blue mr-5"
      :class="[{'card__image--enabled': enabled}]"
    >
      <img
        :src="image"
        alt="8x8"
      >
    </div>
    <div class="w-2/3 flex flex-col justify-between h-full">
      <div>
        <p class="text-black text-sm">
          {{ name }}
        </p>
        <p
          v-if="!enabled"
          class="text-grey-dark text-xs mt-2"
        >
          {{ description }}
        </p>
        <div v-else>
          <p
            v-if="false"
            class="text-grey-dark text-xs mt-2"
          >
            <!-- Enabled on {{ formatDate('11/19/2019') }} -->
            {{ $t('user.auth_card[0]', { date: formatDate('11/19/2019')}) }}
          </p>
          <el-switch
            :value="defaultValue"
            :active-text="$t('app_labels.default')"
            class="mt-2"
            @click.native="toggleDefault"
          />
        </div>
      </div>
      <div class="mt-5">
        <el-button
          v-if="!enabled"
          class="w-full"
          type="primary"
          size="mini"
          @click="enable"
        >
          {{ $t('actions.enable') }}
        </el-button>
        <a
          v-else
          class="text-xs text-red py-2 hover:text-grey cursor-pointer"
          @click="remove"
        >
          {{ $t('actions.remove') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';

export default {
  name: 'AuthCard',

  props: {
    defaultValue: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    recommend: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
  },

  computed: {
    enabled() {
      return this.data && this.data.enabled;
    },
  },

  created() {
    this.defaultValue = this.data ? this.data.default : false;
  },

  methods: {
    getAuthName(authName) {
      let name = authName;
      switch (authName) {
      case 'AUTHENTICATOR_APP':
        name = 'Google';
        break;
      case 'SMS_OTP':
        name = 'SMS';
        break;
      default:
        break;
      }
      return name;
    },

    remove() {
      this.$emit('remove');
    },

    toggleDefault() {
      const payload = { type: this.data.type };
      this.$emit('set-default', payload);
    },

    enable() {
      this.$emit('enable');
    },

    formatDate(dt) {
      return Moment(dt).format('DD MMM YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    display: flex;
    align-items: center;
    word-break: break-word;
    border-color: #EFF3FF;
    transition: border 0.1s ease-in,
                transform 0.1s ease-in;

    &:hover {
      border-color: #DCEAFD;
      transform: translateY(-2px);

      & .card__image {
        filter: grayscale(0%);
      }
    }

    &--tag {
      box-shadow: 0px 0px 5px 0px rgba(220,234,253,1);
    }

    &__tag {
      line-height: 1;
      font-size: 11px;
      padding: 8px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      top: 10px;
      left: 0;

      &--recommend {
        color: #61A0F7;
        background-color: #DCEAFD;
      }

      &--enabled {
        color: #84D0A0;
        background-color: #E0F4E8;
      }
    }

    &__image {
      width: 60px;
      filter: grayscale(100%);
      transition: filter 0.1s ease-in;

      &--enabled {
        filter: grayscale(0%);
      }
    }
  }
</style>
