//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  events: {
    reset() {
      this.$refs.passwordForm.resetFields();
    },
  },

  props: {
    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const confirmValidator = (rules, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validations.required', { value: this.$t('fields.password') })));
      } else if (value.length <= 7 || value.length >= 49) {
        callback(new Error(this.$t('validations.between_char_limit', { value: '8 to 50' })));
      } else if (!value.match(/^(?=.*[a-zA-Z])/g)) {
        callback(new Error(this.$t('validations.atleast_one_letter')));
      } else if (!value.match(/^(?=.*[0-9])/g)) {
        callback(new Error(this.$t('validations.atleast_one_number')));
      } else if (!value.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g)) {
        callback(new Error(this.$t('validations.atleast_one_special_char')));
      } else if (this.strength < 75) {
        callback(new Error(this.$t('validations.weak_password')));
      } else if (value !== this.credentials.newPassword) {
        return callback(new Error(this.$t('validations.not_matched', { value: this.$t('fields.password') })));
      } else {
        callback();
      }

      if ((this.score * 25) < 75) {
        return callback(new Error(this.$t('validations.weak_password')));
      }

      return callback();
    };

    const self = this;

    return {
      score: 0,
      isFetching: false,
      credentials: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      passwordRules: {
        currentPassword: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: this.$t('fields.password') }),
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            validator: confirmValidator,
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          {
            validator: confirmValidator,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    strength() {
      return this.score * 25;
    },
    status() {
      if (this.score < 3) {
        return 'weak';
      }

      if (this.score < 4) {
        return 'good';
      }

      return 'strong';
    },
    disable() {
      return this.errorMessage !== '' && !this.submitted;
    },
    isPasswordValid() {
      return (this.score * 25) >= 75
            && (this.credentials.newPassword !== '' && this.credentials.confirmPassword !== '')
            && (this.credentials.newPassword === this.credentials.confirmPassword);
    },
  },

  // watch: {
  //   resetForm: {
  //     handler(val) {
  //       if (val) {
  //         this.resetFormContent();
  //       }
  //     },
  //   },
  // },

  methods: {
    ...mapActions({
      updatePwd: 'account/updatePassword',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    check() {
      const result = this.$zxcvbn2(this.credentials.newPassword);
      this.score = result.score;
    },

    updatePassword() {
      const { passwordForm } = this.$refs;

      passwordForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.isFetching = true;
        const uid = this.userData.UserId;

        const data = {
          currentPassword: this.credentials.currentPassword,
          newPassword: this.credentials.newPassword,
        };

        this.updatePwd({
          data,
          uid,
        })
          .then(() => {
            this.showMessage(this.$t('success.updated', { value: this.$t('fields.password') }), 'success');
            this.score = 0;
          })
          .catch((response) => {
            if (response && response.data.error) {
              this.$showError(this, response.data);
            } else {
              this.showMessage(response.data.message || response.data, 'error');
            }
          })
          .finally(() => {
            this.isFetching = false;
            passwordForm.resetFields();
          });
      });
    },

    resetFormContent() {
      this.$refs.passwordForm.resetFields();
    },
  },
};
