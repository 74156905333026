<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="Boolean(true)"
    append-to-body
    footer=""
    :title="$t('app_labels.new_features')"
    width="290px"
    :custom-class="darkMode ? 'wa-info new-theme dark-theme' : 'wa-info new-theme light-theme'"
    @closed="closeDialog"
  >
    <template>
      <div class="relative flex flex-col">
        <p
          class="text-xs mb-3"
        >
          {{ $t('sidebar_details.you_can_always_access') }} <br>
          <span class="font-semibold">{{ $t('sidebar_details.my_profile') }}</span>
        </p>
        <div>
          <el-checkbox v-model="checked">
            <span class="text-xs text-color">{{ $t('app_labels.dont_show_again') }}</span>
          </el-checkbox>
        </div>
        <div
          slot="footer"
          class="text-right border-t border-grey-lightest"
          :style="{ paddingTop: '24px', marginTop: '24px' }"
        >
          <el-button
            size="large"
            type="primary"
            @click="closeDialog()"
          >
            {{ $t('actions.ok_got_it') }}!
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: 'WAInfoDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog', { checked: this.checked });
    },
  },
};
</script>
<style lang="scss">

</style>
