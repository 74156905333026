import { handlePackageLimit, handlePackageAddon } from './handle-package-limit';

export default function handleRoutes(userData, routeTo, singleSpa, next) {
  const user = JSON.parse(localStorage.getItem('CPV3_User') || '{}');
  const accountConfig = JSON.parse(user?.AccountConfig || '{}');

  const {
    // AccountId: accountId,
    Roles: roles,
    AccessLevel: accessLevel,
    IsPostPaid: isPostPaid,
    Product_CA: hasChatApps,
    // Product_SMS: hasSMS,
    Product_VI: hasVideo,
    Product_VO: hasVoice,
    Product_AT: hasAutomation,
    Features: features, // eslint-disable-line no-unused-vars
    BetaFeatures: betaFeatures,
  } = userData;

  const { path } = routeTo;
  const pathRegex = /^(\/messaging|\/chat|\/video|\/voice|\/user-management|\/multichannel-sender|\/webhooks|\/verif8|\/auth_sdk|\/pricing|\/automation|\/support)\/?/i;
  const matchResult = path.match(pathRegex);
  const match = matchResult && matchResult.length ? matchResult[0].replace(/\/$/, '') : '';

  const noAccessTo = moduleId => !handlePackageLimit(moduleId);
  const noAddonAccessTo = addonId => !handlePackageAddon(addonId);

  switch (match) {
  case '/messaging': {
    const noDashboard = path === '/messaging' && (!roles.includes('SMS.Reports_v2') || noAccessTo('cp-sms'));
    const noReports = path.match(/\/reports/i) && (!roles.includes('SMS.Reports_v2') || noAccessTo('cp-sms'));
    const noLogs = path.match(/\/logs/i) && (!roles.includes('SMS.Logs_v2') || noAccessTo('cp-sms'));

    const noPayment = path.match(/\/payment/i) && ((!roles.includes('Payment_v2') || isPostPaid) || noAccessTo('cp-payment'));
    const noApiKeys = path.match(/\/api-keys/i) && (!roles.includes('ApiKeys_v2') || noAccessTo('cp-api-keys'));
    const noVirtualNumbers = path.match(/\/virtual-numbers/i) && (!roles.includes('VirtualNumbers_v2') || noAccessTo('cp-number'));
    const noTemplates = path.match(/\/templates/i) && (!roles.includes('Templates_v2') || noAccessTo('cp-templates'));
    const noContacts = path.match(/\/contacts/i) && (!roles.includes('Contacts_v2') || noAccessTo('cp-contacts'));
    const noSenderIdAccess = path.match(/\/sender-id/i) && (!betaFeatures.includes('WEB_SenderId-Register_v1') || noAccessTo('cp-sender-id'));

    if (noDashboard
        || noReports
        || noLogs
        || noPayment
        || noApiKeys
        || noTemplates
        || noContacts
        || noVirtualNumbers
        || noSenderIdAccess
    ) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/chat': {
    const noDashboard = path === '/chat' && (!roles.includes('CA.Reports_v2') || noAccessTo('cp-chatapps'));
    const noReports = path.match(/\/reports/i) && (!roles.includes('CA.Reports_v2') || noAccessTo('cp-chatapps'));
    const noLogs = path.match(/\/logs/i) && (!roles.includes('CA.Logs_v2') || noAccessTo('cp-chatapps'));
    const noChannels = path.match(/\/channels/i) && (!roles.includes('CA.Channels_v2') || noAccessTo('cp-chatapps'));
    const noTemplates = path.match(/\/templates/i) && (!roles.includes('CA.WhatsAppTemplates_v2') || noAccessTo('cp-chatapps'));
    const hasCAPrepaid = betaFeatures.includes('CA-Prepaid');
    const noEmbeddedSignup = path.match(/\/register\/create-wa/i) && (!hasCAPrepaid || (hasCAPrepaid && hasChatApps));

    // Product_CA is false
    if (!hasChatApps) {
      if (hasCAPrepaid) {
        // Account has CA-Prepaid enabled
        if (path === '/chat/register/create-wa') {
          singleSpa.navigateToUrl('/chat/register/create-wa');
        } else {
          singleSpa.navigateToUrl('/chat/register');
        }
      } else {
        // Account has no CA-Prepaid
        singleSpa.navigateToUrl('/chat/register');
      }

      return 0;
    }

    // Product_CA is true
    // Check if user has the specific roles to access the chat apps pages
    if (noDashboard
        || noReports
        || noLogs
        || noChannels
        || noTemplates
        || noEmbeddedSignup
    ) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    if (!hasChatApps && hasCAPrepaid) {
      if (path === '/chat/register/create-wa') {
        singleSpa.navigateToUrl('/chat/register/create-wa');
      } else {
        singleSpa.navigateToUrl('/chat/register');
      }
      return 0;
    }

    next();

    break;
  }

  case '/voice': {
    const noDashboard = path === '/voice' && (!roles.includes('VO.Reports_v2') || noAccessTo('cp-voice'));
    const noReports = path.match(/\/reports/i) && (!roles.includes('VO.Reports_v2') || noAccessTo('cp-voice'));
    const noLogs = path.match(/\/logs/i) && (!roles.includes('VO.Logs_v2') || noAccessTo('cp-voice'));

    if (!hasVoice) {
      singleSpa.navigateToUrl('/voice/register');
      return 0;
    }

    if (noDashboard
        || noReports
        || noLogs
    ) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/video': {
    const noDashboard = path === '/video' && (!roles.includes('VI.CallLogs_v2') || noAccessTo('cp-vi'));
    const noCallLogs = path.match(/(.*)\/call-logs/i) && (!roles.includes('VI.CallLogs_v2') || noAccessTo('cp-vi'));
    const noAgents = path.match(/(.*)\/agents/i) && (!roles.includes('VI.Agents_v2') || noAccessTo('cp-vi'));
    const noPerformance = path.match(/(.*)\/performance/i) && (!roles.includes('VI.Performance_v2') || noAccessTo('cp-vi'));
    const noIncomingCalls = path.match(/(.*)\/incoming-calls/i) && (!roles.includes('VI.IncomingCalls_v2') || noAccessTo('cp-vi'));

    if (!hasVideo || noAccessTo('cp-vi')) {
      singleSpa.navigateToUrl('/video/register');
      return 0;
    }

    if (noDashboard
        || noCallLogs
        || noAgents
        || noPerformance
        || noIncomingCalls
    ) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/user-management': {
    // Check if user can have access to user management
    if ((accessLevel === 'U' && !roles.some(v => v.match(/UserManagement/i))) || noAccessTo('cp-user-mngt')) {
      next('/403');
      return 0;
    }

    next();
    break;
  }

  // matches /multichannel-sender and multichannel-sender/send
  case '/multichannel-sender': {
    const mcsRole = roles.find(role => role === 'MultichannelSender_v2');
    const campaignApproverRole = roles.find(role => role === 'SMS.CampaignApprover_v2');

    const denyAccess = () => {
      singleSpa.navigateToUrl('/403');
      return 0;
    };

    if ((!mcsRole && !campaignApproverRole) || noAccessTo('cp-sender')) {
      return denyAccess();
    }

    if (path === '/multichannel-sender/send' && (!mcsRole || noAccessTo('cp-sender'))) {
      return denyAccess();
    }

    if (path === '/multichannel-sender/send-alerts' && (!mcsRole || noAddonAccessTo('proactive-outreach-alert') || accessLevel === 'U')) {
      return denyAccess();
    }

    next();
    break;
  }

  case '/pricing': {
    if (accountConfig.hide_cost || !roles.includes('Pricing_v2') || noAccessTo('cp-pricing')) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/verif8': {
    const { Product_VERIF8: hasVerif8 } = user;
    const noDashBoardAccess = path.match(/(.*)\/dashboard/i);
    const noReportsAccess = path.match(/(.*)\/logs/i);
    const noLogsAccess = path.match(/(.*)\/reports/i);
    const noTemplatesAccess = path.match(/(.*)\/templates/i);

    if (!hasVerif8 && (noDashBoardAccess || noReportsAccess || noLogsAccess || noTemplatesAccess)) {
      singleSpa.navigateToUrl('/verif8');
      return 0;
    }

    if (!betaFeatures.includes('WEB_Verif8_V1') && (noDashBoardAccess || noReportsAccess || noLogsAccess || noTemplatesAccess)) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }
    next();
    break;
  }

  case '/webhooks': {
    if (!roles.includes('WebHooks_v2') || noAccessTo('cp-webhooks')) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/auth_sdk': {
    if (!betaFeatures.includes('WEB_Auth_SDK_V1')) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/automation': {
    if (noAccessTo('cp-automation') && (roles.includes('Automation_v2') || hasAutomation)) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }

    next();
    break;
  }

  case '/support': {
    // redirect to maintenance page for now
    // next('/maintenance');
    if (noAccessTo('cp-support')) {
      singleSpa.navigateToUrl('/403');
      return 0;
    }
    next();
    break;
  }

  default:
    if (path === '/' || path === '' || path.match(/\/403|\/download|\/process-payment|\/maintenance/)) {
      next();
    } else {
      singleSpa.navigateToUrl('/404');
      return 0;
    }
  }

  return 0;
}
