import http from '../../../../utils/http';

export default {
  // send otp
  async sendOTP({ commit }, payload) {
    try {
      const { data } = await http.v1.post('onboarding/send-code', payload);

      commit('SET_SENT_STATUS', true);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // verify otp
  async verifyOTP({ commit }, payload) {
    try {
      await http.v1.post('onboarding/verify-code', payload);

      commit('SET_VERIFICATION_STATUS', true);

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
