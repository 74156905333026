//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Dropdown',

  props: {
    currOpenApp: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
  },

  methods: {
    toggleDropdown() {
      // If current app and app name is same then set to blank to close
      const appName = this.currOpenApp !== this.appName ? this.appName : '';
      this.$emit('open-app-menu', appName);
    },

    // Set dimensions of element
    onEnter(elem) {
      const { width } = getComputedStyle(elem);

      elem.style.width = width;
      elem.style.position = 'absolute';
      elem.style.visibility = 'hidden';
      elem.style.height = 'auto';

      const { height } = getComputedStyle(elem);

      elem.style.width = null;
      elem.style.position = null;
      elem.style.visibility = null;
      elem.style.height = 0;

      getComputedStyle(elem).height;
      requestAnimationFrame(() => {
        elem.style.height = height;
      });
    },

    // Set to default size to get for reference in onEnter
    onAfterEnter(elem) {
      elem.style.height = 'auto';
    },

    // Set height back to 0
    onLeave(elem) {
      const { height } = getComputedStyle(elem);
      elem.style.height = height;
      getComputedStyle(elem).height;
      requestAnimationFrame(() => {
        elem.style.height = 0;
      });
    },
  },
};
