
import languages from '../locale/lang/languages.json'

const getBrowserLanguage = () => {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  // eslint-disable-next-line max-len
  lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

  let shortLang = lang;
  // eslint-disable-next-line prefer-destructuring
  if (shortLang.indexOf('-') !== -1) { shortLang = shortLang.split('-')[0]; }

  // eslint-disable-next-line prefer-destructuring
  if (shortLang.indexOf('_') !== -1) { shortLang = shortLang.split('_')[0]; }

  return { longName: lang || 'en', shortName: shortLang || 'en' };
};

const getUserLanguage = (langs = [], def = 'en') => {
  const lang = getBrowserLanguage();

  if (langs.includes(lang.shortName)) {
    return lang.shortName;
  }

  return def;
};

const getDefaultLanguage = () => 'en';

const getSelectedLanguage = () => {
  const s = localStorage.getItem('SELECTED_LOCALE');

  const isOnList = languages.find((v) => v.code === s)

  if (isOnList) return s;

  return 'en';
}

export default {
  getBrowserLanguage,
  getUserLanguage,
  getDefaultLanguage,
  getSelectedLanguage,
  languages
};
