<template>
  <div class="google-verification-form clearfix">
    <div class="google-verification__qr-container float-right text-center">
      <div
        v-loading="qrLoading"
        class="google-verification-form__image "
      >
        <img
          class="google-verification-form__qr"
          :src="qrCode"
          alt="qr-code"
        >
        <a
          v-if="!qrLoading && !qrCode"
          class="google-verification-form__qr-refresh"
          @click="generateQrCode"
        >
          <i class="google-verification-form__qr-refresh-icon el-icon-refresh" />
        </a>
      </div>
    </div>
    <div>
      <p class="text-sm text-black mb-2">
        1. {{ $t('user.auth_google_steps[0]') }}
      </p>
      <p class="text-xs text-grey mb-10">
        {{ $t('user.auth_google_steps[1]') }}
      </p>
      <p class="text-sm text-black mb-2">
        2. {{ $t('user.auth_google_steps[2]') }}
      </p>
      <p class="text-xs text-grey mb-10">
        {{ $t('user.auth_google_steps[3]') }}
      </p>

      <span class="text-xs text-grey mb-1 block mt-5">{{ $t('user.auth_google_steps[4]') }}</span>
      <CodeInput
        v-model="otpCode"
        class="flex"
      />

      <div class="flex mt-5">
        <el-button
          size="small"
          :disabled="loading"
          @click="cancel"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          :loading="loading"
          type="primary"
          size="small"
          :disabled="!otpCode || loading"
          @click="submit"
        >
          {{ $t('actions.verify') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CodeInput from './CodeInput.vue';

export default {
  name: 'AuthGoogle',

  components: {
    CodeInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    generateQr: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      qrCode: '',
      qrLoading: false,
      otpCode: '',
    };
  },

  created() {
    this.generateQrCode();
  },

  methods: {
    generateQrCode() {
      this.qrLoading = true;
      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_2fa_otp'))
        .then(token => this.generateQr({ gToken: token }))
        .then((res) => {
          this.qrCode = res;
        })
        .catch((err) => {
          this.qrCode = '';
          this.$showError(this, err);
        })
        .finally(() => {
          this.qrLoading = false;
        });
    },

    submit() {
      if (this.otpCode) {
        const payload = {
          type: 'AUTHENTICATOR_APP',
          token: this.otpCode,
        };
        this.$emit('submit', payload);
      }
    },

    cancel() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.google-verification-form {
  word-break: break-word;

  &__image {
    padding: 10px;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border: solid 1px whitesmoke;
    position: relative;
  }

  &__qr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__qr-container {
    margin-left: 50px;
  }

  &__qr-refresh {
    width: 30px;
    height: 30px;
    color: white;
    background: #3490DC;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.5s ease-in;

    &:hover {
      background: #BCDEFA;
    }
  }

  &__qr-refresh-icon {
    color: white;
    font-size: 23px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}
</style>
