<template>
  <div
    class="nv-dropdown"
    :class="currOpenApp === appName && 'is-open'"
  >
    <div
      class="nv-dropdown__button"
      @click="toggleDropdown()"
    >
      <div class="nv-dropdown__slot">
        <slot name="button" />
      </div>
    </div>
    <transition
      name="expand"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @leave="onLeave"
    >
      <div
        v-if="currOpenApp === appName"
        class="nv-dropdown__options"
      >
        <slot name="options" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    currOpenApp: {
      type: String,
      required: true,
    },
    appName: {
      type: String,
      required: true,
    },
  },

  methods: {
    toggleDropdown() {
      // If current app and app name is same then set to blank to close
      const appName = this.currOpenApp !== this.appName ? this.appName : '';
      this.$emit('open-app-menu', appName);
    },

    // Set dimensions of element
    onEnter(elem) {
      const { width } = getComputedStyle(elem);

      elem.style.width = width;
      elem.style.position = 'absolute';
      elem.style.visibility = 'hidden';
      elem.style.height = 'auto';

      const { height } = getComputedStyle(elem);

      elem.style.width = null;
      elem.style.position = null;
      elem.style.visibility = null;
      elem.style.height = 0;

      getComputedStyle(elem).height;
      requestAnimationFrame(() => {
        elem.style.height = height;
      });
    },

    // Set to default size to get for reference in onEnter
    onAfterEnter(elem) {
      elem.style.height = 'auto';
    },

    // Set height back to 0
    onLeave(elem) {
      const { height } = getComputedStyle(elem);
      elem.style.height = height;
      getComputedStyle(elem).height;
      requestAnimationFrame(() => {
        elem.style.height = 0;
      });
    },
  },
};
</script>

<style scoped>
.nv-dropdown {
  position: relative;
}

.nv-dropdown__button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.nv-dropdown__options {
  position: relative;
}

.nv-dropdown__slot,
.nv-dropdown__slot > *{
  display: block;
  width: 100%;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 0.2s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

.nv-dropdown > * {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
