import actions from './actions';

export default {
  namespaced: true,

  state: {
    sent: false,
    verified: false,
  },
  getters: {
    getSentStatus(state) {
      return state.sent;
    },

    getVerificationStatus(state) {
      return state.verified;
    },
  },

  mutations: {
    SET_SENT_STATUS(state, payload) {
      state.sent = payload; // eslint-disable-line no-param-reassign
    },

    SET_VERIFICATION_STATUS(state, payload) {
      state.verified = payload; // eslint-disable-line no-param-reassign
    },
  },

  actions,
};
