<template>
  <el-popover
    v-model="visible"
    placement="right-start"
    :class="darkMode? 'light-theme': 'dark-theme' "
    :popper-class="`nv-sidebar-wa-popover ${darkMode? 'light-theme': 'dark-theme'}`"
    width="320"
    trigger="manual"
  >
    <div class="flex items-center justify-between nv-sidebar__wa-popover-header">
      <p class="font-semibold">
        <i class="icon-novo-star text-blue font-semibold align-middle text-xl" />
        {{ !isNewlyRegistered ? $t('sidebar_details.whats_app_channel') : $t('sidebar_details.whats_app_enabled') }}
      </p>
      <span
        role="button"
        class="block"
        @click="$emit('close-wa-popover' , false)"
      ><i
        class="icon-novo-close-large text-xl align-middle"
      /></span>
    </div>
    <div
      v-if="!isNewlyRegistered"
      class="nv-sidebar__wa-popover-video-preview"
    >
      <img
        src="../../assets/video-preview.png"
        alt="video-preview"
        width="320"
        height="180"
        class="w-full opacity-50"
      >
    </div>
    <div class=" nv-sidebar__wa-popover-content">
      <p class="text-xs nv-sidebar__wa-popover-body">
        {{ !isNewlyRegistered ? $t('sidebar_details.enable_your_whatsapp') :
          $t('sidebar_details.your_whatsapp_channel_is_ready') }}
      </p>
      <div
        v-if="!isNewlyRegistered"
        class="flex items-center justify-end"
      >
        <el-button
          size="small"
          class="nv-lm-btn"
          @click="redirect('https://videos.8x8.com/watch/VPUto4MErGeETPhw7mhqtQ')"
        >
          {{ $t('actions.learn_more') }}
          <i class="icon-novo-expand align-middle font-semibold ml-1" />
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="redirect('/chat/register')"
        >
          {{ $t('actions.try_now') }}!
        </el-button>
      </div>
      <div
        v-else
        class="flex items-center justify-end"
      >
        <el-button
          size="small"
          type="primary"
          @click="$emit('close-wa-popover', true)"
        >
          {{ $t('actions.ok_got_it') }}!
        </el-button>
      </div>
    </div>
    <span
      slot="reference"
      class="bg-orange nv-sidebar__wa-popover-badge"
      :class="`${isNewlyRegistered ? 'bg-green-darkest' : 'bg-orange'}`"
      @mouseover="$emit('open-wa-popover')"
    />
  </el-popover>
</template>
<script>
export default {
  name: 'WAPopover',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    isNewlyRegistered: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    redirect(link) {
      window.open(link, '_blank');
    },
  },
};
</script>
<style lang="scss">
  .nv-sidebar__wa-popover-badge {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
  }

  .nv-sidebar__wa-popover-body {
    margin-bottom: 24px;
  }

  .nv-sidebar__wa-popover-video-preview {
    background-color: #000;
    margin-bottom: 24px;

    img {
      opacity: 0.5;
    }
  }

  .nv-sidebar__wa-popover-header, .nv-sidebar__wa-popover-content {
    padding: 24px;
  }

  .nv-sidebar__wa-popover-content {
    padding-top: 0 !important;
  }

  .nv-sidebar-wa-popover {
    padding: 0 !important;
  }

  .nv-sidebar-wa-popover.dark-theme {
    background-color: #181619 !important;
    border-color: #181619 !important;

    .popper__arrow,
    .popper__arrow:after {
      border-right-color: #181619 !important;
    }

    p, .icon-novo-close-large {
        color: white !important;
    }
  }

  .nv-sidebar-wa-popover.light-theme {
    background-color: #FFF !important;
    border-color: #FFF !important;

    .popper__arrow,
    .popper__arrow:after {
      border-right-color: #FFF !important;
    }

    p, .icon-novo-close-large {
      color: #292929 !important;
    }

    .nv-lm-btn {
      background: #E0E0E0;
      border: 1px solid #f1f1f1;
      color: #292929;
    }
  }
</style>
