<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="48"
      height="48"
      fill="#CB2233"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5668 24.2033L16.5624 24.2088C17.5679 24.8856 18.3308 25.8332 18.3308 27.2884C18.3308 29.5221 16.493 31.7218 12.8175 31.7218C9.21141 31.7218 7.30432 29.5221 7.30432 27.2208C7.30432 25.3256 8.89934 24.0057 9.35011 23.6673C8.5526 22.9904 7.99781 22.1444 7.99781 20.8922C7.99781 18.7939 9.52348 16.7295 12.9909 16.6957C15.7649 16.6957 18.0187 18.1848 18.0187 20.8922C18.0187 22.4336 16.7269 24.0082 16.5668 24.2033ZM12.9224 19.8428C13.8933 19.8428 14.4828 20.3843 14.4828 21.1965C14.4828 22.0764 14.1014 22.7533 13.9627 22.9902C12.645 22.4487 11.5701 21.9411 11.5701 20.9935C11.5701 20.3504 11.9862 19.8428 12.9224 19.8428ZM11.0153 26.8818C11.0153 27.8294 11.8128 28.5739 12.8183 28.5739C13.9279 28.5739 14.5867 28.0663 14.5867 27.0849C14.5867 26.3361 13.467 25.8462 12.1511 25.2705C12.0397 25.2217 11.9264 25.1722 11.8128 25.122C11.466 25.4943 11.0153 26.1034 11.0153 26.8818Z"
      fill="white"
    />
    <path
      d="M25.1617 22.0094H28.7678L26.0285 26.3412L28.8371 30.7069H25.3004L24.3295 28.9133C24.2255 28.7441 24.0868 28.4395 24.0868 28.4395H24.0521C24.0521 28.4395 23.9134 28.7441 23.8094 28.9133L22.8385 30.7069H19.3017L22.1103 26.3412L19.267 22.0094H22.8731L23.6707 23.5323C23.8094 23.7692 23.9827 24.0399 23.9827 24.0399H24.0174C24.0174 24.0399 24.2255 23.7692 24.3295 23.5323L25.1617 22.0094Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.9272 24.2088L38.9317 24.2034C39.0917 24.0083 40.3836 22.4336 40.3836 20.8922C40.3836 18.1848 38.1297 16.6957 35.3558 16.6957C31.8883 16.6957 30.3627 18.7601 30.3627 20.8583C30.3627 22.1105 30.9175 22.9566 31.715 23.6334C31.2642 23.9719 29.6692 25.2917 29.6692 27.1531C29.6692 29.4544 31.5763 31.6541 35.1824 31.6541C38.8579 31.6541 40.6956 29.4544 40.6956 27.2208C40.6956 25.8332 39.9328 24.8856 38.9272 24.2088ZM35.1822 28.5739C34.1766 28.5739 33.3791 27.8294 33.3791 26.8818C33.3791 26.1034 33.8299 25.4943 34.1766 25.122C34.2903 25.1722 34.4031 25.2215 34.5146 25.2703C35.8305 25.846 36.9506 26.3361 36.9506 27.0849C36.9506 28.0663 36.2918 28.5739 35.1822 28.5739ZM36.8468 21.1965C36.8468 20.3843 36.2574 19.8428 35.2865 19.8428C34.3503 19.8428 33.9342 20.3504 33.9342 20.9935C33.9342 21.9411 35.0091 22.4487 36.3267 22.9902C36.4654 22.7533 36.8468 22.0764 36.8468 21.1965Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: 'Logo',
};
</script>
