import jstz from 'jstimezonedetect';

function install(Vue) {
  if (install.installed) {
    return;
  }
  install.installed = true;

  Object.defineProperty(Vue.prototype, '$jstz2', {
    get() {
      return jstz;
    },
  });
}

export default install;
