<template>
  <el-form
    ref="balanceAlertForm"
    v-loading="isFetching"
    :rules="balanceAlertRules"
    label-position="top"
    :model="balanceAlertSettings"
    @submit="updateBalanceAlertSettings"
  >
    <br>
    <h4 class="mt-2">
      {{ $t('user.alert_settings[0]') }}
    </h4>
    <hr
      class="border border-solid border-t"
    >

    <div class="row profile-form flex flex-wrap">
      <div class="w-full">
        <el-form-item>
          <span>
            <strong>{{ $t('user.alert_settings[1]') }}</strong>
          </span>
          <el-switch
            v-model="alertSettings.balanceAlert"
            prop="balanceAlert"
          />
        </el-form-item>
      </div>

      <div class="w-full">
        <el-form-item
          prop="thresholdAmount"
        >
          <span class="text-sm text-grey">
            {{ $t('user.alert_settings[2]') }}
          </span>
          <br>
          <el-input-number
            v-model="alertSettings.thresholdAmount"
            size="mini"
            controls-position="right"
            :min="1"
            :disabled="!alertSettings.balanceAlert"
          />
          {{ userData.Currency }}
        </el-form-item>
      </div>

      <div class="w-full">
        <h4>
          {{ $t('user.alert_settings[3]') }}
        </h4>
        <div class="text-sm italic email-text py-1">
          {{ $t('user.alert_settings[4]') }}
        </div>
        <br>
        <el-form-item
          prop="selectedEmails"
        >
          <el-select
            v-model="alertSettings.selectedEmails"
            multiple
            filterable
            allow-create
            collapse-tags
            :placeholder="$t('user.alert_settings[5]')"
            class="w-3/4"
            :disabled="!alertSettings.balanceAlert"
            @change="changeEmails"
          >
            <el-option
              v-for="item in alertSettings.allEmails"
              :key="item.email"
              :label="item.label"
              :value="item.email"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="w-1/2 flex-grow">
        &nbsp;
      </div>
      <div class="w-1/2 flex-1 content-right pt-2">
        <el-form-item>
          <el-button
            type="primary"
            native-type="submit"
            class="w-full"
            size="small"
            :disabled="!alertSettings.selectedEmails.length"
            @click.native.prevent="updateBalanceAlertSettings"
          >
            {{ alertSettings.balanceAlert ? $t('actions.update') : $t('actions.disable') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  events: {
    reset() {
      this.$refs.alertSettings.resetFields();
    },
  },

  props: {
    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const validateThreshold = (rules, value, cb) => {
      const { balanceAlert, thresholdAmount } = this.alertSettings;
      if (balanceAlert && (!thresholdAmount || thresholdAmount < 1)) {
        return cb(new Error(this.$t('user.alert_settings[6]')));
      }

      return cb();
    };

    const validateEmails = (rules, value, cb) => {
      const { selectedEmails } = this.alertSettings;

      if (!selectedEmails || !selectedEmails.length) {
        return cb(new Error(this.$t('user.alert_settings[7]')));
      }

      return cb();
    };

    return {
      score: 0,
      isFetching: false,
      alertSettings: {
        balanceAlert: false,
        thresholdAmount: 1,
        selectedEmails: [],
        allEmails: [],
      },
      balanceAlertRules: {
        thresholdAmount: [
          {
            validator: validateThreshold,
            trigger: 'blur',
          },
        ],
        selectedEmails: [
          {
            validator: validateEmails,
            trigger: 'change',
          },
        ],

      },
      adminEmails: [],
      alertEmails: [],
    };
  },

  computed: {
    ...mapGetters({
      balance: 'common/balance',
    }),
    disable() {
      return this.errorMessage !== '' && !this.submitted;
    },
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getBalanceAlertSettings: 'account/getBalanceAlertSettings',
      getAdminEmails: 'account/getAdminEmails',
      updateBalanceAlert: 'account/updateBalanceAlertSettings',
      getBalance: 'common/getBalance',
      getAuthUser: 'common/getAuthUser',
    }),

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    resetFormContent() {
      this.$refs.passwordForm.resetFields();
    },

    changeEmails() {
      const allEmails = this.alertSettings.allEmails.map(e => e.email)
        .filter((v, i, a) => a.indexOf(v) === i);
      // eslint-disable-next-line max-len
      const validEmails = this.alertSettings.selectedEmails.filter(e => e.match(this.$constants.EMAIL_REGEX));
      // eslint-disable-next-line max-len
      let addedEmails = this.alertSettings.selectedEmails.filter(email => !allEmails.find(e => e === email));
      if (addedEmails && addedEmails.length) {
        addedEmails = addedEmails.reduce((acc, curr) => {
          if (curr && curr.match(this.$constants.EMAIL_REGEX)) {
            const email = {
              email: curr,
              label: curr,
            };
            acc.push(email);
          }

          return acc;
        }, []);

        this.alertSettings.allEmails = [...this.alertSettings.allEmails, ...addedEmails]
          .filter((v, i, a) => a.indexOf(v) === i);
      }

      if (validEmails && validEmails.length
        && validEmails.length < this.alertSettings.selectedEmails) {
        this.alertSettings.selectedEmails = [...validEmails];
      }
    },

    updateBalanceAlertSettings() {
      const {
        selectedEmails,
        balanceAlert,
        thresholdAmount,
      } = this.alertSettings;

      const promises = [
        this.updateBalanceAlert({
          accountUid: this.userData.AccountUid,
          data: {
            selectedEmails,
            balanceAlert,
            thresholdAmount,
          },
        }),
      ];

      this.isFetching = true;

      Promise.all(promises)
        .then(async () => {
          await this.fetchData();
          this.$message({
            message: this.$t('success.updated', { value: this.$t('user.alert_settings[0]') }),
            type: 'success',
          });
        }).finally(() => {
          this.isFetching = false;
        });
    },

    fetchData() {
      this.isFetching = true;
      const promises = [
        this.getAdminEmails({ accountUid: this.userData.AccountUid }),
        this.getBalanceAlertSettings({ accountUid: this.userData.AccountUid }),
        this.getBalance(),
        this.getAuthUser(),
      ];

      Promise.all(promises).then((res) => {
        // eslint-disable-next-line no-unused-vars
        const [adminEmails, alertEmails, balanceData, authUser] = res;
        this.adminEmails = adminEmails;
        this.alertEmails = alertEmails;

        if (!authUser || !authUser.data) {
          this.$showError(this, new Error('Error fetching user data'));
          throw new Error('Error fetching user data');
        } else {
          localStorage.setItem('CPV3_User', JSON.stringify(authUser.data));
          const event = new Event('userUpdated');
          window.dispatchEvent(event);
        }

        if (this.adminEmails && Array.isArray(this.adminEmails)) {
          const emails = this.adminEmails.reduce((acc, val) => {
            if (val.Login === val.NotificationEmail && val.FlagBalanceAlert) {
              acc.selectedEmails.push(val.Login);
            }

            acc.allEmails.push({
              email: val.Login,
              label: `${val.Login} (Admin)`,
            });

            return acc;
          }, {
            allEmails: [],
            selectedEmails: [],
          });

          this.alertSettings.allEmails = [...emails.allEmails];
          this.alertSettings.selectedEmails = [...emails.selectedEmails];
        }

        if (this.alertEmails && Array.isArray(this.alertEmails)) {
          this.alertEmails.forEach((val) => {
            if (val.Email && val.FlagBalanceAlert) {
              if (!this.alertSettings.allEmails.some(e => e.email === val.Email)) {
                this.alertSettings.allEmails.push({
                  email: val.Email,
                  label: `${val.Email}`,
                });
              }

              if (!this.alertSettings.selectedEmails.some(e => e === val.Email)) {
                this.alertSettings.selectedEmails.push(val.Email);
              }
            }
          });
        }

        const {
          LowBalanceThreshold,
          LowBalanceAlertEnabled,
        } = this.balance;
        this.alertSettings.balanceAlert = !!LowBalanceAlertEnabled;
        // eslint-disable-next-line max-len
        this.alertSettings.thresholdAmount = LowBalanceThreshold;
      }).catch((err) => {
        this.$showError(this, err);
      }).finally(() => {
        this.isFetching = false;
      });
    },
  },
};
</script>
<style scoped>
  .email-text {
    word-break: break-word;
    line-height: 20px !important;
  }
</style>
