import Vue from 'vue';
import Vuex from 'vuex';

import common from './common';
import account from './account';
import freecredits from './freecredits';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  mutations: {
    API_ERROR(state, payload) {
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    common,
    account,
    freecredits,
  },

  strict: debug,
});

export default store;
