<template>
  <div
    id="settings"
  >
    <el-tabs
      v-model="activeName"
    >
      <el-tab-pane
        :label="$t('user.settings_tab[0]')"
        name="profile"
        :disabled="enforce2FA"
      >
        <update-profile
          ref="updateProfile"
          :user-data.sync="user"
          :reset-form="resetForm"
          @setName="setName"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Preference"
        name="preference"
        :disabled="enforce2FA"
      >
        <preference
          ref="preference"
          :user-data.sync="user"
          :reset-form="resetForm"
          @setName="setName"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('user.settings_tab[1]')"
        name="account"
        :disabled="isNotAdmin() || enforce2FA"
      >
        <update-account
          ref="updateAccount"
          :user-data.sync="user"
          :reset-form="resetForm"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="!userData.SSOEnabled"
        :label="$t('user.settings_tab[2]')"
        name="two-factor"
      >
        <two-factor
          ref="twoFactor"
          :two-fa="twoFa"
          :user-data.sync="user"
          :reset-form="resetForm"
          @enableForm="enableForm"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('user.settings_tab[3]')"
        name="change-password"
        :disabled="enforce2FA"
      >
        <change-password
          ref="changePassword"
          :user-data.sync="user"
          :reset-form="resetForm"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="userData &&
          (userData.CustomerType === 'E'
          || userData.CustomerType === 'W')
          && !userData.IsPostPaid"
        :label="$t('user.settings_tab[4]')"
        name="alert-settings"
        :disabled="isNotAdmin() || enforce2FA"
      >
        <alert-settings
          ref="alertSettings"
          :user-data.sync="user"
          :reset-form="resetForm"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AlertSettings from './partials/AlertSettings.vue';
import ChangePassword from './partials/ChangePassword.vue';
import TwoFactor from './partials/TwoFactor.vue';
import UpdateAccount from './partials/UpdateAccount.vue';
import UpdateProfile from './partials/UpdateProfile.vue';
import Preference from './partials/Preference.vue';

export default {
  name: 'Settings',

  components: {
    UpdateProfile,
    UpdateAccount,
    TwoFactor,
    ChangePassword,
    AlertSettings,
    Preference
  },

  props: {
    tab: {
      type: String,
      default() {
        return 'profile';
      },
    },

    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },

    twoFa: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeName: 'profile',
      user: this.userData,
    };
  },

  computed: {
    ...mapGetters({
      enforce2FA: 'account/getEnforce2FA',
    }),

    ...mapState('common', [
      'timezones',
    ]),
  },

  watch: {
    tab: {
      handler(val) {
        this.activeName = val;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    isNotAdmin() {
      return !!(this.userData && this.userData.AccessLevel && this.userData.AccessLevel !== 'A');
    },
    closeModal() {
      this.$emit('close');
    },
    enableForm(code) {
      this.$emit('enableForm', code);
    },
  },
};
</script>
