<template>
  <div class="text-center">
    <div class="-mx-3 flex">
      <div class="p-3 text-center w-full">
        <img
          class="w-full h-auto px-12"
          :src="step1Icon"
          alt="step 1"
        >
        <p class="mt-2 text-break text-grey">
          {{ $t('user.auto_suggest_2fa[0]') }}
        </p>
      </div>
      <div class="p-3 text-center w-full">
        <img
          class="w-full h-auto px-12"
          :src="step2Icon"
          alt="step 2"
        >
        <p class="mt-2 text-break text-grey">
          {{ $t('user.auto_suggest_2fa[1]') }}
        </p>
      </div>
      <div class="p-3 text-center w-full">
        <img
          class="w-full h-auto px-12"
          :src="step3Icon"
          alt="step 3"
        >
        <p class="mt-2 text-break text-grey">
          {{ $t('user.auto_suggest_2fa[2]') }}
        </p>
      </div>
    </div>
    <div class="mt-8">
      <el-button
        class="mt-5 w-48 mx-auto"
        type="primary"
        size="small"
        @click="setupTwoFa"
      >
        {{ $t('user.auto_suggest_2fa[3]') }}
      </el-button>
      <el-button
        id="do-it-later-btn"
        class="mt-5 w-48 mx-auto"
        size="small"
        @click="close"
      >
        {{ $t('user.auto_suggest_2fa[4]') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import step1Icon from '../../assets/two-fa-step1.svg';
import step2Icon from '../../assets/two-fa-step2.svg';
import step3Icon from '../../assets/two-fa-step3.svg';

export default {
  name: 'AuthHelp',

  data() {
    return {
      step1Icon,
      step2Icon,
      step3Icon,
    };
  },

  methods: {
    setupTwoFa() {
      this.$emit('setup-two-fa');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .text-break {
    word-break: break-word;
  }
</style>
