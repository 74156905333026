//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import AlertSettings from './partials/AlertSettings.vue';
import ChangePassword from './partials/ChangePassword.vue';
import TwoFactor from './partials/TwoFactor.vue';
import UpdateAccount from './partials/UpdateAccount.vue';
import UpdateProfile from './partials/UpdateProfile.vue';
import Preference from './partials/Preference.vue';

export default {
  name: 'Settings',

  components: {
    UpdateProfile,
    UpdateAccount,
    TwoFactor,
    ChangePassword,
    AlertSettings,
    Preference
  },

  props: {
    tab: {
      type: String,
      default() {
        return 'profile';
      },
    },

    resetForm: {
      type: Boolean,
      default() {
        return false;
      },
    },

    userData: {
      type: Object,
      default() {
        return {};
      },
    },

    twoFa: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeName: 'profile',
      user: this.userData,
    };
  },

  computed: {
    ...mapGetters({
      enforce2FA: 'account/getEnforce2FA',
    }),

    ...mapState('common', [
      'timezones',
    ]),
  },

  watch: {
    tab: {
      handler(val) {
        this.activeName = val;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    isNotAdmin() {
      return !!(this.userData && this.userData.AccessLevel && this.userData.AccessLevel !== 'A');
    },
    closeModal() {
      this.$emit('close');
    },
    enableForm(code) {
      this.$emit('enableForm', code);
    },
  },
};
